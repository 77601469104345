import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { ContentfulAssetFile } from '../ContentfulAssetFile/ContentfulAssetFile';
import { Div, Ul } from '../Elements';
import { ContentfulElementAddOn } from '../../../contentful/content-types/blades/blade-addons';
import { RichTextRenderer } from '../RichTextRenderer';
import { richTextOptions } from '../RichTextRenderer/richTextOptions';
import Text from '../Text/Text';
import { Divider } from '../Divider';
import { Icon } from '../Icon';
import { ContentfulLink } from '../links/ContentfulLink';
import { ContentfulButton } from '../buttons/ContentfulButton';

interface BladeAddOnProps {
  addOn: ContentfulElementAddOn;
}

export const BladeAddOn: React.FC<BladeAddOnProps> = ({ addOn }) => {
  return (
    <Div
      border="all"
      paddingVertical={3}
      paddingHorizontal={[3, 4]}
      display={'flex'}
      heightPercentage={100}
      flexDirection="column"
      alignItems="start"
      borderColor="gray2"
    >
      <Div marginBottom={2}>
        <ContentfulAssetFile assetFile={addOn.iconAssetFile} />
      </Div>
      <Text marginBottom={2} variant="headline5" color="black">
        {addOn.title}
      </Text>
      {addOn.description && (
        <Div style={{ flexGrow: 1 }}>
          <RichTextRenderer
            richText={addOn.description}
            options={{
              ...richTextOptions({
                listIcon: (
                  <Icon type="checkmark" className="inline-flex" marginRight={2} color="orange0" />
                ),
              }),
              renderNode: {
                ...richTextOptions({
                  listIcon: (
                    <Icon
                      type="checkmark"
                      className="inline-flex"
                      marginRight={2}
                      color="orange0"
                    />
                  ),
                }).renderNode,
                [BLOCKS.PARAGRAPH]: (node: any, children: any) => (
                  <Text marginBottom={4} marginTop={0} variant="body2">
                    {children}
                  </Text>
                ),
                [BLOCKS.UL_LIST]: (node: any, children: any) => (
                  <Ul className="list" margin={0} padding={0} paddingLeft={0}>
                    {children}
                  </Ul>
                ),
              },
            }}
          />
        </Div>
      )}
      {addOn.learnMoreLink && <ContentfulLink link={addOn.learnMoreLink} />}
      <Divider paddingVertical={2} />
      <Text color="black" marginBottom={addOn.callToAction ? 2 : 0} variant="body2-bold">
        {addOn.pricingText}
      </Text>
      {addOn.callToAction && <ContentfulButton button={addOn.callToAction} />}
    </Div>
  );
};
