import * as React from 'react';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulBladePricingPlans } from '../../../contentful/content-types/blades/blade-pricing-plans/blade-pricing-plans.interface';
import { ButtonCTA } from '../ButtonLink';
import { Divider } from '../Divider';
import { Div, H3, P, Container, Row, Col } from '../Elements';
import { MarkdownBlock } from '../MarkdownBlock';
import { BackgroundColor } from '../../../contentful/content-types/blades/common/interfaces/background-color.interface';
import getPadding from '../BladePadding';
import { Color } from '../Elements/types';
import { ContentfulButton } from '../buttons/ContentfulButton';
import { ContentfulLink } from '../links/ContentfulLink';
import Text from '../Text/Text';
import { Icon } from '../Icon';
import { LocaleLink } from '../links/LocaleLink';
import { EqualHeight, EqualHeightElement } from '../EqualHeight';
import MediaContainer from '../MediaContainer';

export function isContentfulBladePricingPlans(
  blade: RelatedBlade
): blade is ContentfulBladePricingPlans {
  return blade.contentTypeId === 'bladePricingPlans';
}

interface PricingPlansBladeProps {
  blade: ContentfulBladePricingPlans;
}

const getClassnameFromBackgroundColor: { [key in BackgroundColor]: Color } = {
  white: 'white',
  'light-blue': 'blue5',
  blue4: 'blue4',
};

export const PricingPlansBlade: React.FC<PricingPlansBladeProps> = ({ blade }) => {
  const staticStrings = useStaticStrings();

  return (
    <MediaContainer>
      {size => (
        <EqualHeight>
          <Div
            backgroundColor={getClassnameFromBackgroundColor[blade.backgroundColor] || 'white'}
            {...getPadding(blade.sectionPadding, blade.contentTypeId)}
          >
            <Container>
              {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
                <Divider paddingVertical={0} />
              )}
              <Div>
                <Row marginBottom={0}>
                  <Col lg={7}>
                    {blade.title && (
                      <H3 marginBottom={[1, 10]} fontSize={[6, 8]} lineHeight={5}>
                        {blade.title}
                      </H3>
                    )}
                    {blade.description && <P>{blade.description}</P>}
                  </Col>
                </Row>
                <Row>
                  {blade.plansList?.map((plan, i, plansList) => (
                    <Col
                      marginBottom={[7, 0, 0, 0]}
                      sm={plansList.length === 4 ? '3' : plansList.length === 2 ? '5' : '4'}
                      key={plan.contentTypeId + i}
                    >
                      {!plan.hideTopDivider && (
                        <Divider paddingTop={0} paddingBottom={3} paddingVertical={0} />
                      )}

                      <EqualHeightElement disable={size.xs} name="title-and-description">
                        {plan.type && <H3 color={'orange0'}>{plan.type}</H3>}
                        {plan.description && (
                          <Text variant="body2" marginBottom={0} marginTop={2}>
                            {plan.description}
                          </Text>
                        )}
                      </EqualHeightElement>
                      <Divider paddingVertical={3} />
                      <EqualHeightElement disable={size.xs} name="price-section">
                        <EqualHeightElement disable={size.xs} name="price">
                          {plan.price && (
                            <H3 marginBottom={1}>
                              {plan.priceCurrencySymbol}
                              {plan.price}
                            </H3>
                          )}
                        </EqualHeightElement>
                        <EqualHeightElement disable={size.xs} name="billingSubtext">
                          {plan.billingSubtext && (
                            <Text color="gray0" variant="body2" marginBottom={2}>
                              {plan.billingSubtext}
                            </Text>
                          )}
                        </EqualHeightElement>
                        {plan.ctaButtonText && !plan.ctaButton && (
                          <EqualHeightElement disable={size.xs} name="ctaButtonText">
                            <ButtonCTA
                              fontWeight="bold"
                              border="all"
                              color="white"
                              buttonColor="orange"
                              width="100%"
                              marginBottom={!plan.featureList1 && !plan.additionalTermsText ? 0 : 1}
                              className="pointer"
                              href={plan.ctaButtonUrl}
                              data-tracking-category={`${plan.type}_plan`}
                              data-tracking-action="click"
                              data-tracking-label={`${plan.ctaButtonText}-${plan.ctaButtonUrl}`}
                            >
                              {plan.ctaButtonText}
                            </ButtonCTA>
                          </EqualHeightElement>
                        )}
                        {plan.ctaButton && (
                          <EqualHeightElement disable={size.xs} name="ctaButton">
                            <ContentfulButton
                              data-tracking-category={`${plan.type}_plan`}
                              data-tracking-action="click"
                              data-tracking-label={`${plan.ctaButtonText}-${plan.ctaButtonUrl}`}
                              marginBottom={!plan.featureList1 && !plan.additionalTermsText ? 0 : 1}
                              button={plan.ctaButton}
                            />
                          </EqualHeightElement>
                        )}

                        {plan.additionalTermsText && (
                          <EqualHeightElement disable={size.xs} name="additionalTermsText">
                            <Text color="gray0" marginBottom={0} variant="body3">
                              {plan.additionalTermsText ? plan.additionalTermsText : ''}
                            </Text>
                          </EqualHeightElement>
                        )}
                      </EqualHeightElement>
                      <Divider paddingVertical={3} />
                      <EqualHeightElement disable={size.xs} name="features">
                        {plan.featureList1 && Array.isArray(plan.featureList1.features) && (
                          <EqualHeightElement disable={size.xs} name="featureList1">
                            {plan.featureList1.title && (
                              <Text
                                className="ttu"
                                marginTop={0}
                                color="orange0"
                                tag="P"
                                variant="small-caps"
                                marginBottom={3}
                              >
                                {plan.featureList1.title}
                              </Text>
                            )}
                            <EqualHeightElement disable={size.xs} name="features-1">
                              {plan.featureList1?.features?.map((feature, index) => (
                                <Div
                                  key={`${blade.contentfulId}-${plan.contentfulId}-${feature.contentfulId}-${index}`}
                                  marginBottom={
                                    plan.featureList1?.features &&
                                    plan.featureList1?.features.length - 1 === index &&
                                    !plan.supportResponse
                                      ? 0
                                      : 3
                                  }
                                  display="flex"
                                  alignItems="start"
                                >
                                  <Div marginRight={2} flexShrink={0}>
                                    <Icon type="checkmark" color="orange0" />
                                  </Div>
                                  <MarkdownBlock
                                    renderers={{
                                      paragraph: ({ children }) => (
                                        <Text
                                          tag="P"
                                          marginTop={0}
                                          marginBottom={0}
                                          variant="body2-bold"
                                        >
                                          {children}
                                        </Text>
                                      ),
                                    }}
                                  >
                                    {feature.title}
                                  </MarkdownBlock>
                                </Div>
                              ))}
                            </EqualHeightElement>
                          </EqualHeightElement>
                        )}
                        {plan.featureList2 && <Divider paddingVertical={3} />}
                        {plan.featureList2 && Array.isArray(plan.featureList2.features) && (
                          <EqualHeightElement disable={size.xs} name="featureList2">
                            {plan.featureList2.title && (
                              <Text
                                marginTop={0}
                                color="orange0"
                                className="ttu"
                                tag="P"
                                variant="small-caps"
                                marginBottom={3}
                              >
                                {plan.featureList2.title}
                              </Text>
                            )}
                            <EqualHeightElement disable={size.xs} name="features2">
                              {plan.featureList2?.features?.map((feature, index) => (
                                <Div
                                  key={`${blade.contentfulId}-${plan.contentfulId}-${feature.contentfulId}-${index}`}
                                  marginBottom={
                                    plan.featureList2?.features &&
                                    plan.featureList2?.features.length - 1 === index &&
                                    !plan.supportResponse
                                      ? 0
                                      : 3
                                  }
                                  display="flex"
                                  alignItems="start"
                                >
                                  <Div marginRight={2} flexShrink={0}>
                                    <Icon type="checkmark" color="orange0" />
                                  </Div>
                                  <MarkdownBlock
                                    renderers={{
                                      paragraph: ({ children }) => (
                                        <Text
                                          tag="P"
                                          marginTop={0}
                                          marginBottom={0}
                                          variant="body2-bold"
                                        >
                                          {children}
                                        </Text>
                                      ),
                                    }}
                                  >
                                    {feature.title}
                                  </MarkdownBlock>
                                </Div>
                              ))}
                            </EqualHeightElement>
                          </EqualHeightElement>
                        )}
                        {plan.featureList3 && <Divider paddingVertical={3} />}
                        {plan.featureList3 && Array.isArray(plan.featureList3.features) && (
                          <EqualHeightElement disable={size.xs} name="featureList3">
                            {plan.featureList3.title && (
                              <Text
                                marginTop={0}
                                color="orange0"
                                className="ttu"
                                tag="P"
                                variant="small-caps"
                                marginBottom={3}
                              >
                                {plan.featureList3.title}
                              </Text>
                            )}
                            <EqualHeightElement disable={size.xs} name="features3">
                              {plan.featureList3?.features?.map((feature, index) => (
                                <Div
                                  key={`${blade.contentfulId}-${plan.contentfulId}-${feature.contentfulId}-${index}`}
                                  marginBottom={
                                    plan.featureList3?.features &&
                                    plan.featureList3?.features.length - 1 === index &&
                                    !plan.supportResponse
                                      ? 0
                                      : 3
                                  }
                                  display="flex"
                                  alignItems="start"
                                >
                                  <Div marginRight={2} flexShrink={0}>
                                    <Icon type="checkmark" color="orange0" />
                                  </Div>
                                  <MarkdownBlock
                                    renderers={{
                                      paragraph: ({ children }) => (
                                        <Text
                                          tag="P"
                                          marginTop={0}
                                          marginBottom={0}
                                          variant="body2-bold"
                                        >
                                          {children}
                                        </Text>
                                      ),
                                    }}
                                  >
                                    {feature.title}
                                  </MarkdownBlock>
                                </Div>
                              ))}
                            </EqualHeightElement>
                          </EqualHeightElement>
                        )}
                        {plan.supportResponse && (
                          <Text variant="body2">
                            <Text variant="body2-bold" tag="Span">
                              {staticStrings.json_values['Support_Response']}:
                            </Text>{' '}
                            {plan.supportResponse}
                          </Text>
                        )}
                      </EqualHeightElement>
                      <Divider
                        paddingTop={3}
                        paddingBottom={plan.bottomCtaLinkText || plan.bottomCtaLink ? 2 : 0}
                      />

                      {plan.bottomCtaLinkUrl && plan.bottomCtaLinkText && !plan.bottomCtaLink && (
                        <P margin={0}>
                          <LocaleLink
                            to={plan.bottomCtaLinkUrl}
                            fontWeight="bold"
                            color="blue1"
                            className="learn-more"
                          >
                            {plan.bottomCtaLinkText}
                          </LocaleLink>
                        </P>
                      )}
                      {plan.bottomCtaLink && (
                        <ContentfulLink margin={0} link={plan.bottomCtaLink} />
                      )}
                    </Col>
                  ))}
                </Row>
              </Div>
            </Container>
          </Div>
          <Container>
            {(blade.bladeBorder === 'border-bottom' || blade.bladeBorder === 'border') && (
              <Divider paddingVertical={0} />
            )}
          </Container>
        </EqualHeight>
      )}
    </MediaContainer>
  );
};
