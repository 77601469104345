import * as React from 'react';
import { isContentfulBladeHero, HeroBlade } from './HeroBlade';
import { isContentfulBladeEnablement, EnablementBlade } from './EnablementBlade';
import { TrustedByBlade, isContentfulBladeTrustedBy } from './TrustedByBlade';
import {
  isContentfulBladeMultipleFeatures,
  FeaturesBlade,
} from './multipleFeaturesBlade/FeaturesBlade';
import { isContentfulBladeKeyFeatures, KeyFeaturesBlade } from './KeyFeaturesBlade';
import { isContentfulBladeImageText, ImageTextBlade } from './image-text-blade/ImageTextBlade';
import { BladeFeatureList, isContentfulBladeFeatureList } from './FeatureListBlade';
import { isContentfulBladeQuote, QuoteBlade } from './QuoteBlade';
import { BladeSubNav, isContentfulBladeSubNav } from '../blades/BladeSubNav';
import { isRichTextGroupBlade, RichTextColumnBlade } from './RichTextColumnBlade';
import { ContentfulPage } from '../../../contentful/content-types/page/page.interface';
import { isContentfulBladePricingPlans, PricingPlansBlade } from './PricingPlansBlade';
import { BladeForm, isFormBlade } from './BladeForm';
import { isAccordionBlade, AccordionBlade } from './AccordionBlade';
import { BladeSignUpForm, isSignUpFormBlade } from './BladeSignUpForm';
import { BladeDoubleContent, isDoubleContentBlade } from './BladeDoubleContent';
import { BladeEnterpriseForm, isEnterpriseFormBlade } from './BladeEnterpriseForm';
import { BladeHtml, isHtmlBlade } from './HtmlBlade';
import { BladeResourceGrid, isContentfulBladeResourceGrid } from './BladeResourceGrid';
import { RelatedBlade } from '../../../contentful/blades';
import { isPersonalizedBlade, Personalize } from '../Personalizer/Personalize';
import { BladeTable, isContentfulBladeTable } from './BladeTable';
import { BladeTabs, isContentfulBladeTabs } from './BladeTabs';
import { BladeAddOns, isBladeAddOns } from './BladeAddOns';
import { isNavigationBlade, NavigationBlade } from './NavigationBlade/NavigationBlade';
import { BladeNetworkStats, isContentfulBladeNetworkStats } from './BladeNetworkStats';
import {
  ContentfulPsaBanner,
  isContentfulPsaBanner,
} from '../../../components/layout/ContentfulPsaBanner';
import { isBladePlansMatrix, BladePlansMatrix, ModalPlansMatrix } from './BladePlansMatrix';
import { BladeContentfulPadding, isContenfulBladePadding } from './BladeContentfulPadding';

interface BladesProps {
  refreshStyles?: boolean;
  pagePrefix?: string;
  page: ContentfulPage;
}

interface BladeRenderedProps {
  blade: RelatedBlade;
  pagePrefix?: string;
  page: ContentfulPage;
  index: number;
}

export const Blade: React.FC<BladeRenderedProps> = ({ blade, pagePrefix, page, index: i }) => {
  if (isContentfulBladeHero(blade)) {
    return <HeroBlade blade={blade} page={page} />;
  } else if (isContentfulBladeEnablement(blade)) {
    return <EnablementBlade page={page} blade={blade} />;
  } else if (isContentfulBladeMultipleFeatures(blade)) {
    return <FeaturesBlade blade={blade} />;
  } else if (isContentfulBladeImageText(blade)) {
    return <ImageTextBlade blade={blade} />;
  } else if (isContentfulBladeTrustedBy(blade)) {
    return <TrustedByBlade blade={blade} />;
  } else if (isContentfulBladeKeyFeatures(blade)) {
    return <KeyFeaturesBlade blade={blade} />;
  } else if (isContentfulBladeQuote(blade)) {
    return <QuoteBlade blade={blade} />;
  } else if (isContentfulBladeFeatureList(blade)) {
    return <BladeFeatureList blade={blade} />;
  } else if (isContentfulBladeSubNav(blade)) {
    return <BladeSubNav blade={blade} pagePrefix={pagePrefix || ''} />;
  } else if (isRichTextGroupBlade(blade)) {
    return <RichTextColumnBlade blade={blade} />;
  } else if (isFormBlade(blade)) {
    return <BladeForm blade={blade} page={page} />;
  } else if (isContentfulBladePricingPlans(blade)) {
    return <PricingPlansBlade blade={blade} />;
  } else if (isAccordionBlade(blade)) {
    return <AccordionBlade blade={blade} />;
  } else if (isSignUpFormBlade(blade)) {
    return <BladeSignUpForm blade={blade} page={page} />;
  } else if (isDoubleContentBlade(blade)) {
    return <BladeDoubleContent blade={blade} />;
  } else if (isEnterpriseFormBlade(blade)) {
    return <BladeEnterpriseForm blade={blade} page={page} />;
  } else if (isHtmlBlade(blade)) {
    return <BladeHtml blade={blade} />;
  } else if (isContentfulBladeResourceGrid(blade)) {
    return <BladeResourceGrid blade={blade} />;
  } else if (isPersonalizedBlade(blade)) {
    return <Personalize blade={blade} pagePrefix={pagePrefix || ''} page={page} />;
  } else if (isContentfulBladeTable(blade)) {
    return <BladeTable blade={blade} />;
  } else if (isContentfulBladeTabs(blade)) {
    return <BladeTabs blade={blade} page={page} />;
  } else if (isBladeAddOns(blade)) {
    return <BladeAddOns blade={blade} />;
  } else if (isNavigationBlade(blade)) {
    return <NavigationBlade blade={blade} />;
  } else if (isContentfulBladeNetworkStats(blade)) {
    return <BladeNetworkStats blade={blade} />;
  } else if (isContentfulPsaBanner(blade)) {
    return <ContentfulPsaBanner banner={blade} />;
  } else if (isBladePlansMatrix(blade)) {
    if (blade.showInModal) {
      return <ModalPlansMatrix blade={blade} />;
    }
    return <BladePlansMatrix blade={blade} />;
  } else if (isContenfulBladePadding(blade)) {
    return <BladeContentfulPadding blade={blade} />;
  }
  return null;

  // console.error('Invalid blade', blade);
  // throw new Error('Invalid blade type');
};

export const Blades: React.FC<BladesProps> = ({ pagePrefix, page }) => (
  <>
    {page.relatedBlades &&
      page?.relatedBlades?.map((blade, i) => (
        <Blade
          key={`${blade.contentTypeId}-${i}`}
          index={i}
          pagePrefix={pagePrefix || ''}
          page={page}
          blade={blade}
        />
      ))}
  </>
);
