import React from 'react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import slugify from 'slugify';
import Text from '../Text/Text';
import { A, Hr, Img, Li, Ol, Span, Ul } from '../Elements';
import { LocaleLink } from '../links/LocaleLink';
import { RichTextEntryRenderer } from './EntryRenderer';
import { getLocaleEntry } from './richtext.utils';
import { Options } from '@contentful/rich-text-react-renderer';
import {
  EntryPage,
  RelatedAsset,
} from '../../../contentful/content-types/blades/common/interfaces/common.interface';
import { RichtextcustomOptions } from './richtext.interface';
import { AssetFile } from '../ContentfulAssetFile/interfaces/asset-file.interface';
import { Icon } from '../Icon';
import { Lazy } from '../Lazy';

export const richTextOptions = (options?: RichtextcustomOptions): Options => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => (
      <Text
        tag="P"
        marginBottom={4}
        marginTop={0}
        variant="body1"
        {...options?.PARAGRAPH_styleprops}
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_1]: (node: any, children: any) => (
      <Text
        tag="H1"
        marginBottom={3}
        variant="headline1"
        {...getIdPropsForHeaderChildren(children)}
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_2]: (node: any, children: any) => (
      <Text
        tag="H2"
        marginBottom={2}
        variant="headline2"
        {...getIdPropsForHeaderChildren(children)}
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_3]: (node: any, children: any) => (
      <Text
        tag="H3"
        marginBottom={2}
        variant="headline3"
        {...getIdPropsForHeaderChildren(children)}
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_4]: (node: any, children: any) => (
      <Text
        tag="H4"
        marginBottom={2}
        variant="headline4"
        {...getIdPropsForHeaderChildren(children)}
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_5]: (node: any, children: any) => (
      <Text
        tag="H5"
        marginBottom={2}
        variant="headline5"
        {...getIdPropsForHeaderChildren(children)}
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_6]: (node: any, children: any) => (
      <Text tag="P" marginBottom={2} variant="small-caps">
        {children}
      </Text>
    ),
    [BLOCKS.QUOTE]: (node: any, children: any) => (
      <Text tag="Div" className="rt-quote" marginBottom={2} variant="quote1">
        {children}
      </Text>
    ),
    [BLOCKS.UL_LIST]: (node: any, children: any) => (
      <Ul className={options?.listIcon ? 'list' : ''} margin={0} padding={0} paddingLeft={2}>
        {children}
      </Ul>
    ),
    [BLOCKS.OL_LIST]: (node: any, children: any) => (
      <Ol margin={0} padding={0} paddingLeft={2}>
        {children}
      </Ol>
    ),
    [BLOCKS.LIST_ITEM]: (node: any, children: any) => (
      <Li color="orange0">
        <Span display="flex" color="black">
          {options?.listIcon && <Span display="inline-flex">{options?.listIcon}</Span>}
          <Span>{children}</Span>
        </Span>
      </Li>
    ),
    [BLOCKS.HR]: (node: any, children: any) => <Hr border="top" borderColor="gray2" />,
    [INLINES.HYPERLINK]: (node: any, children: any) => (
      <A
        fontWeight={7}
        color="blue1"
        display="inline-flex"
        alignItems="center"
        href={node.data.uri}
      >
        {children}
      </A>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
      const relatedAsset: RelatedAsset | RelatedAsset[] = node?.data?.target?.related_asset;
      const media = getLocaleEntry<RelatedAsset>(relatedAsset);
      const file = media?.file;
      const contentType = (file?.internal?.mediaType || file?.contentType)?.split('/')[0];
      switch (contentType) {
        case 'image': {
          return (
            <Lazy>
              <Img width="100%" src={file?.publicURL} alt={media?.title} />
            </Lazy>
          );
        }
        default: {
          return (
            <A
              fontWeight={7}
              display="flex"
              color="blue1"
              alignItems="center"
              target="_blank"
              href={file?.publicURL || ''}
              marginBottom={7}
            >
              {children}{' '}
              <Span lineHeight={0} marginLeft={0}>
                <Icon type="link-caret" />
              </Span>
            </A>
          );
        }
      }
    },
    [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
      const relatedAsset: RelatedAsset | RelatedAsset[] = node?.data?.target?.related_asset;
      const file = getLocaleEntry<RelatedAsset>(relatedAsset)?.file;
      return (
        <A
          fontWeight={7}
          display="inline-flex"
          color="blue1"
          alignItems="center"
          target="_blank"
          href={file?.publicURL || ''}
        >
          {children}
        </A>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node: any, children: any) => {
      const relatedPage: EntryPage | EntryPage[] = node?.data?.target?.entry_page;
      const relatedAssetFile: AssetFile | AssetFile[] = node?.data?.target?.entry_assetFile;

      const page = getLocaleEntry<EntryPage>(relatedPage);
      const asset = getLocaleEntry<AssetFile>(relatedAssetFile);

      if (page) {
        return (
          <LocaleLink className="blue1" to={page?.relativePath || ''} variant="link">
            <Span
              fontWeight={7}
              display="inline-flex"
              alignItems="center"
              target="_blank"
              marginBottom={0}
            >
              {children}
            </Span>
          </LocaleLink>
        );
      }
      if (asset) {
        return (
          <A
            fontWeight={7}
            display="inline-flex"
            color="blue1"
            alignItems="center"
            target="_blank"
            href={
              asset?.brandfolderAsset?.file.publicURL ||
              asset.contentfulAsset?.file?.publicURL ||
              ''
            }
          >
            {children}
          </A>
        );
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node: any, children: any) => {
      return <RichTextEntryRenderer type="inline" targetNode={node?.data?.target} />;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: any) => {
      return <RichTextEntryRenderer type="block" targetNode={node?.data?.target} />;
    },
  },
  renderMark: {
    [MARKS.BOLD]: (text: any) => <Span fontWeight={6}>{text}</Span>,
    [MARKS.UNDERLINE]: (text: any) => (
      <u>
        <Span fontWeight="normal">{text}</Span>
      </u>
    ),
    [MARKS.ITALIC]: (text: any) => (
      <i>
        <Span fontWeight="normal">{text}</Span>
      </i>
    ),
  },
  renderText: (text: string) =>
    text
      .replace(/\u00a0/g, ' ')
      // Newline support, adapted from docs:
      // https://www.npmjs.com/package/@contentful/rich-text-react-renderer
      .split('\n')
      .reduce<any>((children, textSegment, index) => {
        if (index > 0) {
          children.push(<br key={textSegment + index} />);
        }

        children.push(textSegment);

        return children;
      }, []),
});

function getIdPropsForHeaderChildren(children: any): undefined | { id: string } {
  const slugifyOptions = { remove: /[,*+~.()'"!:@]/g, lower: true };

  if (typeof children === 'string') {
    return { id: slugify(children, slugifyOptions) };
  }

  if (Array.isArray(children)) {
    // Rich text nodes that are just text are string[][]
    const flattendChildren = children.flat();

    if (flattendChildren.some(child => typeof child === 'string')) {
      return {
        id: flattendChildren
          .filter(child => typeof child === 'string')
          .map(child => slugify(child, slugifyOptions))
          .join('-'),
      };
    }
  }
}
