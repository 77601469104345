import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../layout/layout';
import { Blades } from '../common/refresh-blades/RefreshBlades';
import { GatsbyTemplateProps } from '../../common/interfaces/gatsby-template-props.interface';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { HrefLangs } from '../layout/refreshed-header/HrefLangs';
import { Locale } from '../../common/util/locales';
import { MetaTags } from '../../contentful/content-types/elements/meta-tags.interface';
import { ContentfulPage } from '../../contentful/content-types/page/page.interface';
import { PageTemplates } from './templates/PageTemplates';
import { ZendeskChat } from '../../common/util/zendeskChat';
import { joinPaths } from '../../common/util/path';
import { useCMSState } from '@internal/useCMSHook';
import { useConfiguration } from '../../common/hooks/useConfiguration';
import { MainFooter } from '../layout/refreshed-footer/footer.interfaces';
import { ContentfulNavNavigationGroup } from '../../contentful/content-types/nav-navigation-group';

export const query = graphql`
  query($locale: String!, $contentfulId: String!) {
    page(contentfulId: { eq: $contentfulId }, locale: { eq: $locale }) {
      ...pageFragment
    }
    headerData: navNavigationGroup(
      contentfulId: { eq: "3hzSjT58plRWzuQrC4j4ny" }
      locale: { eq: $locale }
    ) {
      ...navNavigationGroupFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    site {
      siteMetadata {
        baseURL
      }
    }
  }
`;
export interface PageParams
  extends GatsbyTemplateProps<AppContextProps & { allowedHrefLangs?: Locale[] }> {
  data: {
    page: ContentfulPage;
    headerData: ContentfulNavNavigationGroup;
    footerData: MainFooter;
    site: any;
  };
}

const CmsPage: FC<PageParams> = ({ data, pageContext }) => {
  const config = useConfiguration();
  let { page, footerData, headerData } = data;
  if (config.targetEnv === 'staging') {
    page = useCMSState<ContentfulPage>(page);
    footerData = useCMSState<MainFooter>(footerData);
    headerData = useCMSState<ContentfulNavNavigationGroup>(headerData);
  }
  const baseURL = data.site?.siteMetadata?.baseURL || 'https://www.cloudflare.com';

  return (
    <Layout
      pageContext={pageContext}
      footerData={footerData}
      headerData={headerData}
      psaBanner={page.psaBanner}
      topNavOptions={page.topNavOptions}
      customNav={page.customTopNav}
      customHeaderLogoUrl={
        page.headerLogo?.file?.publicURL ? page.headerLogo.file.publicURL : undefined
      }
      customCTAButton={{ text: page.topNavButtonText, url: page.topNavButtonUrl }}
      hrefLangs={HrefLangs(
        pageContext.locale,
        pageContext.pathname,
        pageContext.baseURL,
        (pageContext.allowedHrefLangs as any) || undefined
      )}
    >
      <Helmet>
        {getMetaTagsJSX(baseURL, page.metaTags)}
        {page.noindex && <meta name="robots" content="noindex, nofollow"></meta>}
      </Helmet>

      {page.enableZendeskChat && <ZendeskChat />}
      {page.relatedBlades && (
        <Blades page={page} pagePrefix="/refresh" refreshStyles={true}></Blades>
      )}
      {page.template && <PageTemplates page={page} pagePrefix="/refresh" refreshStyles={true} />}
    </Layout>
  );
};

/**
 * Returns a list of elements for react-helmet. Since react-helmet can't
 * support sub-components or fragments, we'll just return an array of jsx
 * @param metaTags An object that has a reference to metaTags
 */
export function getMetaTagsJSX(baseURL: string, metaTags?: MetaTags) {
  if (!metaTags) return [];

  return [
    metaTags.metaTitle && (
      <title key={metaTags.metaTitle}>{`${metaTags.metaTitle} | Cloudflare`}</title>
    ),
    metaTags.metaTitle && (
      <meta key={metaTags.metaTitle} name="title" content={`${metaTags.metaTitle} | Cloudflare`} />
    ),
    metaTags.metaDescription && (
      <meta key={metaTags.metaDescription} name="description" content={metaTags.metaDescription} />
    ),
    metaTags.metaImage && metaTags.metaImage.file && (
      <meta
        name="image"
        key={metaTags.metaImage.file.publicURL}
        content={metaTags.metaImage.file.publicURL}
      />
    ),
    <meta
      name="twitter:card"
      key={
        metaTags.twitterCustomImage?.file || metaTags.metaImage?.file
          ? 'summary_large_image'
          : 'summary'
      }
      content={
        metaTags.twitterCustomImage?.file || metaTags.metaImage?.file
          ? 'summary_large_image'
          : 'summary'
      }
    />,
    metaTags.metaTitle && (
      <meta
        name="twitter:title"
        key={metaTags.metaTitle}
        content={`${metaTags.metaTitle} | Cloudflare`}
      />
    ),
    (metaTags.twitterCustomImage || metaTags.metaImage) && (
      <meta
        name="twitter:image"
        key={metaTags.metaTitle}
        content={
          metaTags.twitterCustomImage?.file
            ? joinPaths(baseURL, metaTags.twitterCustomImage.file.publicURL)
            : metaTags.metaImage?.file
            ? joinPaths(baseURL, metaTags.metaImage.file?.publicURL)
            : '../../../static/img/cf-twitter-card.png'
        }
      />
    ),
    metaTags.metaDescription && (
      <meta
        key={metaTags.metaTitle}
        name="twitter:description"
        content={metaTags.metaDescription}
      />
    ),
    metaTags.metaTitle && (
      <meta key={metaTags.metaTitle} property="og:title" content={metaTags.metaTitle} />
    ),
    metaTags.metaDescription && (
      <meta key={metaTags.metaTitle} property="og:description" content={metaTags.metaDescription} />
    ),
    (metaTags.facebookCustomImage || metaTags.metaImage) && (
      <meta
        key={metaTags.metaTitle}
        property="og:image"
        content={
          metaTags.facebookCustomImage?.file
            ? joinPaths(baseURL, metaTags.facebookCustomImage.file.publicURL)
            : metaTags.metaImage?.file
            ? joinPaths(baseURL, metaTags.metaImage.file.publicURL)
            : '../../../static/img/cf-facebook-card.png'
        }
      />
    ),
  ];
}

export default CmsPage;
