import { groupBy } from 'lodash';
import React from 'react';
import sectionPadding from '../BladePadding';
import { RelatedBlade } from '../../../contentful/blades';
import {
  ContentfulElementAddOn,
  ContentfulBladeAddOns,
} from '../../../contentful/content-types/blades/blade-addons';
import { Div, Container, Row, Col, Button, Label } from '../Elements';
import Text from '../Text/Text';
import { TextVariantMap } from '../Text/Text.interface';
import { BladeAddOn } from './ElementAddOn';
import { SelectDropdown } from '../SelectDropdown';
import { ContentfulButton } from '../buttons/ContentfulButton';
import { Divider } from '../Divider';

interface BladeAddOnsProps {
  blade: ContentfulBladeAddOns;
}

export function isBladeAddOns(blade: RelatedBlade): blade is ContentfulBladeAddOns {
  return blade.contentTypeId === 'bladeAddOns';
}

export const BladeAddOns: React.FC<BladeAddOnsProps> = ({ blade }) => {
  let groupedAddons = groupBy(blade.addOns, 'category');
  const [selectedGroup, setSelectedGroup] = React.useState<string>(Object.keys(groupedAddons)[0]);
  const [renderedAddOns, setRenderedAddons] = React.useState<ContentfulElementAddOn[]>(
    blade.addOns || []
  );

  React.useEffect(() => {
    if (blade.grouped) {
      const filteredAddOns = blade.addOns?.filter(addOn => addOn.category === selectedGroup);
      setRenderedAddons(filteredAddOns || []);
    }
  }, [blade.grouped, selectedGroup, blade.addOns]);

  const isHorizontal = blade.variant === 'Horizontal';
  const isSingleItemHorizontal = isHorizontal && blade.addOns && blade.addOns?.length <= 1;

  return (
    <Div id={blade.htmlId} backgroundColor={blade.backgroundColor}>
      <Container>
        {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
          <Divider paddingVertical={0} />
        )}
        <Div {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}>
          <Row>
            <Col lg={isSingleItemHorizontal ? 5 : isHorizontal ? 4 : 12}>
              <Row marginBottom={[6, 7]}>
                <Col lg={isHorizontal ? 12 : 6}>
                  {!!blade.caption && (
                    <Text variant="small-caps" marginBottom={1} color="orange0">
                      {blade.caption}
                    </Text>
                  )}
                  {!!blade.title && (
                    <Text variant="headline3" marginBottom={2} color="black">
                      {blade.title}
                    </Text>
                  )}
                  {!!blade.description && (
                    <Text variant="body1" marginBottom={blade.callToAction ? 7 : 0} color="black">
                      {blade.description}
                    </Text>
                  )}
                  {blade.callToAction && <ContentfulButton button={blade.callToAction} />}
                </Col>
              </Row>
            </Col>
            <Col
              lg={{
                span: isSingleItemHorizontal ? 6 : isHorizontal ? 8 : 12,
                offset: isSingleItemHorizontal ? 1 : 0,
              }}
            >
              {blade.grouped && (
                <Row marginBottom={5}>
                  <Col>
                    <Div display={['none', 'flex', 'flex', 'flex']} flexWrap="wrap">
                      {Object.keys(groupedAddons)?.map(group => (
                        <Button
                          className="pointer"
                          marginRight={5}
                          color="blue1"
                          paddingVertical={1}
                          paddingHorizontal={0}
                          backgroundColor="transparent"
                          key={group}
                          border="bottom"
                          borderWidth={2}
                          borderColor={selectedGroup === group ? 'blue1' : 'transparent'}
                          onClick={() => setSelectedGroup(group)}
                          {...TextVariantMap['learnmore']}
                        >
                          {group}
                        </Button>
                      ))}
                    </Div>
                    <Label display={['block', 'none', 'none', 'none']} htmlFor="group">
                      <Text marginBottom={1} display="none" variant="body2-bold">
                        {selectedGroup}
                      </Text>
                      <SelectDropdown
                        id="group"
                        arrowColor="black"
                        value={selectedGroup || undefined}
                        styleprops={{
                          width: '100%',
                          paddingVertical: 2,
                          ...TextVariantMap.body2,
                        }}
                        title="group"
                        name="group"
                        data-key="group"
                        onChange={e => setSelectedGroup((e.target as any).value)}
                      >
                        {Object.keys(groupedAddons)?.map((group, i) => (
                          <option key={`group-${group}`} value={group}>
                            {group}
                          </option>
                        ))}
                      </SelectDropdown>
                    </Label>
                  </Col>
                </Row>
              )}
              <Row>
                {renderedAddOns?.map(addOn => (
                  <Col
                    marginBottom={4}
                    key={addOn.contentfulId}
                    md={6}
                    lg={isSingleItemHorizontal ? 8 : isHorizontal ? 6 : 4}
                  >
                    <BladeAddOn addOn={addOn} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Div>
        {(blade.bladeBorder === 'border-bottom' || blade.bladeBorder === 'border') && (
          <Divider paddingVertical={0} />
        )}
      </Container>
    </Div>
  );
};
