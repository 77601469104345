import * as React from 'react';
import { ContentfulPage } from '../../../contentful/content-types/page/page.interface';
import { Blade } from '../../common/refresh-blades/RefreshBlades';

interface BladesProps {
  refreshStyles?: boolean;
  pagePrefix?: string;
  page: ContentfulPage;
}

export const TemplateBladeList: React.FC<BladesProps> = ({ page, pagePrefix }) => (
  <>
    {page.template?.relatedBlades &&
      page.template?.relatedBlades.map((blade, i) => (
        <Blade
          key={`${page.template?.contentfulId}-${blade.contentTypeId}-${i}`}
          index={i}
          pagePrefix={pagePrefix || ''}
          page={page}
          blade={blade}
        />
      ))}
  </>
);
