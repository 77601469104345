import React from 'react';
import sectionPadding from '../../BladePadding';
import { useLocation, navigate } from '@reach/router';
import { RelatedBlade } from '../../../../contentful/blades';
import { ContentfulNavigation } from '../../../../contentful/content-types/navigation';
import { Div, Container, Label } from '../../Elements';
import { LocaleLink } from '../../links/LocaleLink';
import Text from '../../Text/Text';
import { SelectDropdown } from '../../SelectDropdown';
import { TextVariantMap } from '../../Text/Text.interface';
import { useLocale } from '../../../../common/hooks/useLocale';
import { resolveUrl } from '../../../../common/util/path';
import { ContentfulNavigationItem } from 'src/contentful/content-types/navigationitem';

interface NavigationBladeProps {
  blade: ContentfulNavigation;
}

interface TabStyleNavigationItemProps {
  item: ContentfulNavigationItem;
  index: number;
  activeLink: ContentfulNavigationItem;
}

export function isNavigationBlade(blade: RelatedBlade): blade is ContentfulNavigation {
  return blade.contentTypeId === 'navNavigation';
}

const TabStyleNavigationItem: React.FC<TabStyleNavigationItemProps> = ({
  item,
  activeLink,
  index,
}) => {
  const [hovered, setHovered] = React.useState<boolean>(false);
  const isActive = activeLink.contentfulId === item.contentfulId;

  return (
    <LocaleLink
      display="flex"
      marginRight={[3, 3, 3, 4]}
      marginLeft={index !== 0 ? [0, 0, 0, '4px'] : undefined}
      paddingHorizontal={2}
      backgroundColor={isActive || hovered ? 'white' : 'blue3'}
      paddingVertical={1}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      flexDirection="column"
      to={item.mainLink?.url}
    >
      <Text variant="headline5" color="black" marginBottom="4px" tag="Span">
        {item.name}
      </Text>
      <Text display={['none', 'none', 'none', 'block']} variant="body3" color="gray0" tag="Span">
        {item.description}
      </Text>
    </LocaleLink>
  );
};

const TabStyleNavigation: React.FC<NavigationBladeProps> = ({ blade }) => {
  const location = useLocation();
  const locale = useLocale();
  const selectedNav =
    blade.navigationItems?.find(item => location.pathname.includes(item.mainLink.url)) ||
    blade.navigationItems[0];

  const handleMobileNav = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const goTo = blade.navigationItems?.find(item => item.contentfulId === e.target.value);
    if (goTo) {
      navigate(resolveUrl(locale, goTo.mainLink?.url));
    }
  };

  const activeLink =
    blade.navigationItems?.find(item => location.pathname.includes(item.mainLink.url)) ||
    blade.navigationItems?.[0];

  return (
    <Div
      {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}
      backgroundColor={blade.backgroundColor}
    >
      <Container display="flex" flexWrap="wrap">
        <Div width="100%" display={['none', 'flex']}>
          {blade.navigationItems?.map((item, index) => {
            return (
              <TabStyleNavigationItem
                index={index}
                activeLink={activeLink}
                item={item}
                key={item.contentfulId}
              />
            );
          })}
        </Div>
        <Label width="100%" display={['block', 'none', 'none', 'none']} htmlFor="nav">
          <Text marginBottom={1} display="none" variant="body2-bold">
            {selectedNav?.name}
          </Text>
          <SelectDropdown
            id="nav"
            value={selectedNav?.contentfulId}
            styleprops={{
              width: '100%',
              paddingVertical: 1,
              ...TextVariantMap.body2,
            }}
            title="nav"
            name="nav"
            data-key="nav"
            onChange={handleMobileNav}
          >
            {blade.navigationItems?.map((group, i) => (
              <option key={`nav-${group.contentfulId}`} value={group?.contentfulId}>
                {group?.name}
              </option>
            ))}
          </SelectDropdown>
        </Label>
      </Container>
    </Div>
  );
};

export const NavigationBlade: React.FC<NavigationBladeProps> = ({ blade }) => {
  if (blade.navigationType === 'Tabbed') {
    return <TabStyleNavigation blade={blade} />;
  }
  return null;
};
