import React from 'react';
import { CFInfo } from '../CFIcons/CFInfo';
import { CFXExit } from '../CFIcons/CFXExit';
import { Button, Div, Span } from '../Elements';
import MediaContainer from '../MediaContainer';
import Text from '../Text/Text';

interface TooltipProps {
  title: string;
  description: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ title, description }) => {
  const [showToolTip, setShowToolTip] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const handleToolTip = () => {
    setShowToolTip(!showToolTip);
  };

  React.useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      if (ref.current != null && !ref.current.contains(e.target as any)) {
        setShowToolTip(!showToolTip);
      }
    };
    if (showToolTip) {
      window.addEventListener('click', onClickOutside);
    }
    return () => {
      window.removeEventListener('click', onClickOutside);
    };
  }, [showToolTip]);
  return (
    <Span lineHeight={0} marginLeft={2}>
      <Button
        display="flex"
        lineHeight={0}
        padding={0}
        backgroundColor="transparent"
        border="none"
        className="pointer"
        onClick={handleToolTip}
      >
        <CFInfo size={16} color="gray1" />
      </Button>
      <div ref={ref}>
        <Div
          style={{
            top: 0,
            transition: 'width 2s linear 1s',

            right: 0,
          }}
          position="absolute"
          zIndex={1}
          // heightPercentage={size.lg ? undefined : 100}
          backgroundColor="blue4"
          padding={3}
          justifyContent="center"
          alignItems="center"
          display={showToolTip ? 'flex' : 'none'}
        >
          <Div
            heightPercentage={100}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            position="relative"
          >
            <Div display="flex">
              <Text flex="auto" marginBottom={1} variant="body1-bold">
                {title}
              </Text>
              <Button
                onClick={handleToolTip}
                display="flex"
                style={{ top: 0, right: 0 }}
                lineHeight={0}
                padding={0}
                position={['absolute', 'absolute', 'absolute', 'relative']}
                className="pointer"
                backgroundColor="transparent"
                border="none"
              >
                <CFXExit size={14} color="black" />
              </Button>
            </Div>
            <Text variant="body2">{description}</Text>
          </Div>
        </Div>
      </div>
    </Span>
  );
};
