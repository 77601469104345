import React from 'react';
import MediaContainer from '../../common/MediaContainer';
import sectionPadding from '../BladePadding';
import { RelatedBlade } from 'src/contentful/blades';
import {
  ContentfulElementTab,
  ContentfulBladeTabs,
} from 'src/contentful/content-types/blades/blade-tabs';
import { ContentfulPage } from 'src/contentful/content-types/page';
import { Divider } from '../Divider';
import { Col, Container, Div, Row, Span } from '../Elements';
import { MarkdownBlock } from '../MarkdownBlock';
import Text from '../Text/Text';
import { Blade } from './RefreshBlades';

export function isContentfulBladeTabs(blade: RelatedBlade): blade is ContentfulBladeTabs {
  return blade.contentTypeId === 'bladeTabs';
}

interface BladeTabsProps {
  blade: ContentfulBladeTabs;
  page: ContentfulPage;
}
export const BladeTabs: React.FC<BladeTabsProps> = ({ blade, page }) => {
  const [selectedTab, setSelectedTab] = React.useState<ContentfulElementTab>(blade.tabs[0]);

  return (
    <MediaContainer>
      {size => (
        <Div backgroundColor={blade.backgroundColor} id={blade.anchorTag}>
          <Container>
            {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
              <Divider paddingVertical={0} />
            )}
            <Div {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}>
              <Row>
                <Col lg={2}>
                  <Div
                    display="flex"
                    style={{ overflowX: size.sm ? 'visible' : 'auto' }}
                    flexDirection={['row', 'row', 'row', 'column']}
                  >
                    {blade.tabs?.map(tab => (
                      <Div
                        role="button"
                        position="relative"
                        className="pointer"
                        onClick={() => setSelectedTab(tab)}
                        marginBottom={4}
                        marginRight={[4, 4, 4, 0]}
                        key={tab.contentfulId}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {tab.contentfulId === selectedTab.contentfulId && (
                          <Span
                            position="absolute"
                            display={['none', 'none', 'none', 'block']}
                            style={{ left: '-8px', width: 2 }}
                            heightPercentage={100}
                            backgroundColor="blue1"
                          />
                        )}

                        {tab.contentfulId === selectedTab.contentfulId && (
                          <Span
                            position="absolute"
                            display={['block', 'block', 'block', 'none']}
                            style={{ bottom: '-4px', height: 2 }}
                            width="100%"
                            backgroundColor="blue1"
                          />
                        )}

                        <Text
                          variant="body1-bold"
                          lineHeight={1}
                          marginBottom="4px"
                          color={
                            tab.contentfulId === selectedTab.contentfulId || size.xs
                              ? 'blue1'
                              : 'black'
                          }
                        >
                          {tab.title}
                        </Text>
                        <Div
                          width="100%"
                          display={['none', 'none', 'none', 'block']}
                          style={{ whiteSpace: 'normal' }}
                        >
                          <MarkdownBlock
                            color="gray0"
                            source={tab.description}
                            renderers={{
                              paragraph: ({ children }) => (
                                <Text variant="body3" color="gray0">
                                  {children}
                                </Text>
                              ),
                            }}
                          />
                        </Div>
                      </Div>
                    ))}
                  </Div>
                </Col>
                <Col lg={{ span: 9, offset: 1 }}>
                  <Div className={size.sm || size.xs ? 'row' : ''}>
                    {selectedTab.tabContent?.map((blade, index) => (
                      <Blade
                        key={`${blade.contentTypeId}-${index}`}
                        blade={blade}
                        page={page}
                        index={index}
                      />
                    ))}
                  </Div>
                </Col>
              </Row>
            </Div>
            {(blade.bladeBorder === 'border-bottom' || blade.bladeBorder === 'border') && (
              <Divider paddingVertical={0} />
            )}
          </Container>
        </Div>
      )}
    </MediaContainer>
  );
};
