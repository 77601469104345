import * as React from 'react';
import { Div } from '../../Elements';
import { ButtonCTA } from '../../buttons/Button';
import { ContentfulBladeImageText } from '../../../../contentful/content-types/blades/blade-image-text';

interface ImageTextInlineButtonsProps {
  blade: ContentfulBladeImageText;
}
export const ImageTextInlineButtons: React.FC<ImageTextInlineButtonsProps> = ({ blade }) => {
  return (
    <>
      {/* Old functionality of buttons, using fields to populate, this checks if both buttons fields are filled out then render it in one row on desktop */}
      {blade.button1Text && blade.button2Text && (
        <Div display="flex" flexDirection="row" flexWrap="wrap" marginTop={[6, 6, 6, 7]}>
          {blade.button1Text && (
            <ButtonCTA
              buttonColor={blade.button1Color}
              href={blade.button1Url}
              marginRight={[0, 0, 0, 4]}
              marginBottom={blade.button2Text ? 2 : 0}
            >
              {blade.button1Text}
            </ButtonCTA>
          )}
          {blade.button2Text && (
            <ButtonCTA
              buttonColor={blade.button2Color}
              href={blade.button2Url}
              marginRight={[0, 0, 0, 4]}
              marginBottom={0}
            >
              {blade.button2Text}
            </ButtonCTA>
          )}
        </Div>
      )}

      {/* Only render Button 1 if Button 2 is not filled out */}

      {blade.button1Text && !blade.button2Text && (
        <ButtonCTA
          marginTop={[2, 4]}
          buttonColor={blade.button1Color}
          href={blade.button1Url}
          marginRight={[0, 4]}
          marginBottom={blade.button2Text || blade.button ? 2 : 0}
        >
          {blade.button1Text}
        </ButtonCTA>
      )}

      {/* Only render Button 2 if Button 1 is not filled out */}

      {blade.button2Text && !blade.button1Text && (
        <ButtonCTA
          marginTop={[6, 7]}
          buttonColor={blade.button2Color}
          href={blade.button2Url}
          marginRight={[0, 4]}
          marginBottom={blade.button2Text || blade.button ? 2 : 0}
        >
          {blade.button2Text}
        </ButtonCTA>
      )}
    </>
  );
};
