import * as React from 'react';
import { Div } from '../../Elements';
import { ContentfulBladeImageText } from '../../../../contentful/content-types/blades/blade-image-text';
import { ContentfulButton } from '../../buttons/ContentfulButton';

interface ImageTextButtonReferencesProps {
  blade: ContentfulBladeImageText;
}
export const ImageTextButtonReferences: React.FC<ImageTextButtonReferencesProps> = ({ blade }) => {
  return (
    <>
      {/* New Functionality of attaching a Contentful Button type that supports modals in button, render 2 buttons if there are 2 buttons attached to blade */}

      {blade.button?.[0] && blade.button?.[1] && (
        <Div display="flex" flexDirection="row" flexWrap="wrap" marginTop={[6, 6, 6, 7]}>
          <ContentfulButton
            bladeContentTypeId={blade.contentTypeId}
            marginRight={[0, 0, 0, 4]}
            marginBottom={blade.button[1] ? 2 : 0}
            button={blade.button[0]}
          />
          <ContentfulButton
            marginRight={[0, 0, 0, 4]}
            bladeContentTypeId={blade.contentTypeId}
            button={blade.button[1]}
          />
        </Div>
      )}

      {blade.button && blade.button.length === 1 && blade.button[0] && (
        <ContentfulButton
          bladeContentTypeId={blade.contentTypeId}
          marginRight={[0, 4]}
          marginBottom={0}
          button={blade.button[0]}
        />
      )}
    </>
  );
};
