import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ContentfulAssetFile } from '../ContentfulAssetFile/ContentfulAssetFile';
import { Divider } from '../Divider';
import { Div, H3, H2 } from '../Elements';
import { InterpolatedText } from '../InterpolatedText';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulBladeTrustedBy } from '../../../contentful/content-types/blades/blade-trusted-by';
import sectionPadding from '../BladePadding';
import { Lazy } from '../Lazy';
export function isContentfulBladeTrustedBy(blade: RelatedBlade): blade is ContentfulBladeTrustedBy {
  return blade.contentTypeId === 'bladeTrustedBy';
}

interface TrustedByBladeProps {
  blade: ContentfulBladeTrustedBy;
}

export const getClassnameFromBackgroundColor = new Map([
  ['light-blue', 'bg-blue5'],
  ['light-gray', 'bg-gray4'],
  ['white', 'white'],
]);

function isFullWidthLogoList(blade: Pick<ContentfulBladeTrustedBy, 'copy' | 'title'>) {
  return !blade.copy && !blade.title;
}

function getMarginBottom(iconsLength: Number) {
  return iconsLength < 8 ? 'pb9-ns' : 'pb6-ns';
}

function getLengthOfLogoList(blade: ContentfulBladeTrustedBy) {
  if (Array.isArray(blade.logoListAssetFile)) {
    return blade.logoListAssetFile.length;
  }
  return blade.logoList.filter(f => f.file).length;
}

const LogoList: React.FC<TrustedByBladeProps> = ({ blade }) => {
  if (Array.isArray(blade.logoListAssetFile)) {
    return (
      <>
        {blade.logoListAssetFile.map((assetFile, i) => {
          return (
            <Col
              className="flex items-center justify-center mb6-ns mb4"
              xs={6}
              sm={isFullWidthLogoList(blade) ? 2 : 3}
              key={`${blade.contentfulId}asset-file${assetFile.id}`}
            >
              <ContentfulAssetFile className="mw-100 w-100" assetFile={assetFile} />
            </Col>
          );
        })}
      </>
    );
  } else {
    // MRK-11990 It seems in some instances in the preview environment
    // there is a file linked, but without the url set. This results in the
    // look up `f.file.publicURL` to throw an error, file is undefined. I'm
    // unsure how this happens, but this fixes the particular exception.
    // It's almost certain that this problem crop up later we'll need to
    // solve the general case
    return (
      <>
        {blade.logoList
          .filter(f => f.file)
          .map((f, i) => (
            <Col
              className="flex items-center justify-center mb6-ns mb4"
              xs={6}
              sm={isFullWidthLogoList(blade) ? 2 : 3}
              key={`${blade.contentfulId}asset-file${f.file?.publicURL}`}
            >
              <Lazy>
                <img className="mw-100 w-100" title="logo" alt="logo" src={f.file?.publicURL} />
              </Lazy>
            </Col>
          ))}
      </>
    );
  }
};

export const TrustedByBlade: React.FC<TrustedByBladeProps> = ({ blade }) => (
  <Div
    className={getClassnameFromBackgroundColor.get(blade.backgroundColor) || 'white'}
    color={blade.textColor || 'black'}
  >
    <Container>
      {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
        <Divider marginVertical={0} paddingVertical={0} />
      )}
      <Div
        className={`${getMarginBottom(getLengthOfLogoList(blade))}`}
        {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}
      >
        <Row>
          {!isFullWidthLogoList(blade) && (
            <Col xs={12} sm={4}>
              {blade.copy ? (
                <H3 className={`${!blade.linkText && !blade.copy ? 'pb6' : ''} ma0`}>
                  <InterpolatedText text={blade.title} />
                </H3>
              ) : (
                <H3 className={`${!blade.linkText && !blade.copy ? 'pb6' : ''} ma0 word-break`}>
                  <InterpolatedText text={blade.title} />
                </H3>
              )}
              {blade.copy && (
                <Div>
                  <p className="ma0 pt2 pb4 body-2">
                    <InterpolatedText text={blade.copy} />
                  </p>
                </Div>
              )}
              {blade.linkText && (
                <Div className={`pb0-ns ${!blade.copy && 'pt4'} pb6`}>
                  <a href={blade.linkUrl} className="learn-more">
                    {blade.linkText}
                  </a>
                </Div>
              )}
            </Col>
          )}
          <Col
            xs={12}
            sm={isFullWidthLogoList(blade) ? 12 : 8}
            lg={isFullWidthLogoList(blade) ? 12 : 8}
          >
            <Row>
              <LogoList blade={blade} />
            </Row>
          </Col>
        </Row>
      </Div>
      {(blade.bladeBorder === 'border-bottom' || blade.bladeBorder === 'border') && (
        <Divider marginVertical={0} />
      )}
    </Container>
  </Div>
);

export default TrustedByBlade;
