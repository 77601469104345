import React from 'react';
import loadable from '@loadable/component';

export const EqualHeight = loadable(
  () => import('react-equal-height').then(mod => mod.EqualHeight),
  {
    ssr: false,
  }
);

export const EqualHeightElement = loadable(
  () => import('react-equal-height').then(mod => mod.EqualHeightElement),
  {
    ssr: false,
  }
);
