import React from 'react';
import { Div, Container, Row, Col } from '../Elements';
import Text from '../Text/Text';
import { richTextOptions } from '../RichTextRenderer/richTextOptions';
import { RichTextRenderer } from '../RichTextRenderer';
import { ContentfulRichTextColumns } from '../../../contentful/content-types/blades/blade-richtext-columns';
import { RelatedBlade } from '../../../contentful/blades';
import sectionPadding from '../BladePadding';
import { Divider } from '../Divider';

interface DoubleContentBladeProps {
  blade: ContentfulRichTextColumns;
}

export const getClassnameFromBackgroundColor = new Map([
  ['blue', 'bg-blue0'],
  ['white', 'bg-white'],
  ['light-blue', 'bg-blue5'],
]);

export function isRichTextGroupBlade(blade: RelatedBlade): blade is ContentfulRichTextColumns {
  return blade.contentTypeId === 'bladeRichTextColumnGroup';
}

export const RichTextColumnBlade: React.FC<DoubleContentBladeProps> = ({ blade }) => {
  return (
    <Div
      {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}
      className={`${getClassnameFromBackgroundColor.get(blade.backgroundColor || 'bg-white')}`}
    >
      <Container>
        {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
          <Divider marginVertical={0} />
        )}
        <Row marginBottom={[5, 5, 5, 7]}>
          <Col lg={7}>
            <Text variant="headline2">{blade.mainHeadline}</Text>
          </Col>
        </Row>
        <Row>
          {blade.columns.map((column, index: number) => {
            const [span, offset] = getColumnSpanAndOffset(blade, index);

            return (
              <Col key={`${column.contentfulId}-${index}`} lg={{ span, offset }}>
                <RichTextRenderer options={richTextOptions()} richText={column.column} />
              </Col>
            );
          })}
        </Row>
        {(blade.bladeBorder === 'border-bottom' || blade.bladeBorder === 'border') && (
          <Divider marginVertical={0} />
        )}
      </Container>
    </Div>
  );
};

function getColumnSpanAndOffset(blade: ContentfulRichTextColumns, index: number): [number, number] {
  if (blade.columnLayout) {
    switch (blade.columnLayout) {
      case 'One Column Wide':
        return [12, 0];
      case 'One Column Narrow':
        return [6, 3];
      case 'Half & Half':
        return index === 0 ? [5, 0] : [5, 1];
      case 'One Third & Two Thirds':
        return index === 0 ? [4, 0] : [7, 1];
      case 'Two Thirds & One Third':
        return index === 0 ? [7, 0] : [4, 1];
      case 'Left Sidebar':
        return index === 0 ? [3, 0] : [8, 1];
      case 'Right Sidebar':
        return index === 0 ? [8, 0] : [3, 1];
    }
  }

  // Default strategy
  if (blade.columns.length === 1) {
    return [6, 3];
  }

  if (blade.columns.length === 2) {
    return [5, 1];
  }

  return [12, 0];
}
