import React from 'react';
import { RelatedBlade } from 'src/contentful/blades';
import { ContentfulBladePadding } from 'src/contentful/content-types/blades/blade-padding';
import { Div } from '../Elements';

interface BladeContentfulPaddingProps {
  blade: ContentfulBladePadding;
}

export function isContenfulBladePadding(blade: RelatedBlade): blade is ContentfulBladePadding {
  return blade.contentTypeId === 'bladePadding';
}

export const BladeContentfulPadding: React.FC<BladeContentfulPaddingProps> = ({ blade }) => {
  return <Div height={blade.height} backgroundColor={blade.backgroundColor} />;
};
