import React from 'react';
import { Div, Container, Row, Col } from '../Elements';
import { richTextOptions } from '../RichTextRenderer/richTextOptions';
import { RichTextRenderer } from '../RichTextRenderer';
import { ContentfulBladeForms } from '../../../contentful/content-types/blades/blade-forms';
import { RelatedBlade } from '../../../contentful/blades';
import { OnSuccessMessage } from '../Marketo/marketo-success-message';
import { ContentfulPage } from '../../../contentful/content-types/page';
import { useLocale } from '../../../common/hooks/useLocale';
import { getCamelCasedLocale } from './BladeSignUpForm';
import { CFCheck } from '../CFIcons/CFCheck';
import sectionPadding from '../BladePadding';
import { Divider } from '../Divider';
import { MarketoFormStyled } from '../Marketo/marketo-styled-form';

interface FormBladeProps {
  blade: ContentfulBladeForms;
  page?: ContentfulPage;
}

export function isFormBlade(blade: RelatedBlade): blade is ContentfulBladeForms {
  return blade.contentTypeId === 'bladeForms';
}

export const BladeForm: React.FC<FormBladeProps> = ({ blade, page }) => {
  const { bladeForm } = blade;
  const locale = getCamelCasedLocale(useLocale());
  const isEnglishOnly = page?.localeList?.[locale] === 'English for Locale';
  return (
    <Div backgroundColor={blade.backgroundColor || 'white'} color={blade.textColor || 'black'}>
      {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
        <Divider paddingVertical={0} className="container" />
      )}
      <Container {...sectionPadding(blade.sectionPadding, blade.contentTypeId)} color={'black'}>
        <Row display="flex" marginBottom={[6, 6, 6, 0]}>
          <Col lg={5}>
            <RichTextRenderer
              options={richTextOptions({
                listIcon: <CFCheck size={32} className="inline-flex" color="orange0" />,
                PARAGRAPH_styleprops: {
                  marginBottom: 6,
                },
              })}
              richText={blade.richTextEntry.column}
            />
          </Col>
          <Col lg={{ span: 5, offset: 2 }}>
            {bladeForm && (
              <MarketoFormStyled
                onSuccess={() => null}
                title={blade.formHeader}
                subtitle={blade.formCopy}
                marketoData={blade.bladeForm}
                SuccessElement={() =>
                  bladeForm ? <OnSuccessMessage form={bladeForm} ctaAction={() => null} /> : null
                }
                isEnglishOnly={isEnglishOnly}
              />
            )}
          </Col>
        </Row>
      </Container>
      {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
        <Divider paddingVertical={0} className="container" />
      )}
    </Div>
  );
};
