import { isContentfulBladeHero } from '../../../components/common/refresh-blades/HeroBlade';
import { isContentfulBladeEnablement } from '../../../components/common/refresh-blades/EnablementBlade';
import { isContentfulBladeTrustedBy } from '../../../components/common/refresh-blades/TrustedByBlade';
import { isContentfulBladeMultipleFeatures } from '../refresh-blades/multipleFeaturesBlade/FeaturesBlade';
import { isContentfulBladeKeyFeatures } from '../../../components/common/refresh-blades/KeyFeaturesBlade';
import { isContentfulBladeImageText } from '../refresh-blades/image-text-blade/ImageTextBlade';
import { isContentfulBladeFeatureList } from '../../../components/common/refresh-blades/FeatureListBlade';
import { isContentfulBladeQuote } from '../../../components/common/refresh-blades/QuoteBlade';
import { isContentfulBladeSubNav } from '../../../components/common/blades/BladeSubNav';
import { isRichTextGroupBlade } from '../../../components/common/refresh-blades/RichTextColumnBlade';
import { isContentfulBladePricingPlans } from '../../../components/common/refresh-blades/PricingPlansBlade';
import { isFormBlade } from '../../../components/common/refresh-blades/BladeForm';
import { isAccordionBlade } from '../../../components/common/refresh-blades/AccordionBlade';
import { isSignUpFormBlade } from '../../../components/common/refresh-blades/BladeSignUpForm';
import { isDoubleContentBlade } from '../../../components/common/refresh-blades/BladeDoubleContent';
import { isEnterpriseFormBlade } from '../../../components/common/refresh-blades/BladeEnterpriseForm';
import { isHtmlBlade } from '../../../components/common/refresh-blades/HtmlBlade';
import { isContentfulBladeResourceGrid } from '../../../components/common/refresh-blades/BladeResourceGrid';
import { RelatedBlade } from '../../../contentful/blades';

export const isRelatedBlade = (entry: any): entry is RelatedBlade => {
  return (
    isContentfulBladeHero(entry) ||
    isContentfulBladeEnablement(entry) ||
    isContentfulBladeTrustedBy(entry) ||
    isContentfulBladeMultipleFeatures(entry) ||
    isContentfulBladeKeyFeatures(entry) ||
    isContentfulBladeImageText(entry) ||
    isContentfulBladeFeatureList(entry) ||
    isContentfulBladeQuote(entry) ||
    isContentfulBladeSubNav(entry) ||
    isRichTextGroupBlade(entry) ||
    isContentfulBladePricingPlans(entry) ||
    isFormBlade(entry) ||
    isAccordionBlade(entry) ||
    isSignUpFormBlade(entry) ||
    isDoubleContentBlade(entry) ||
    isEnterpriseFormBlade(entry) ||
    isHtmlBlade(entry) ||
    isContentfulBladeResourceGrid(entry)
  );
};
