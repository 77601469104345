import React from 'react';
import sectionPadding from '../BladePadding';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulBladeNetworkStats } from '../../../contentful/content-types/blades/blade-network-stats';
import { Col, Container, Div, Row, Span } from '../Elements';
import { ContentfulLink } from '../links/ContentfulLink';
import { LocaleLink } from '../links/LocaleLink';
import { MarkdownBlock } from '../MarkdownBlock';
import Text from '../Text/Text';
import { QuoteSection } from './QuoteBlade';
import { Divider } from '../Divider';
import { InterpolatedText } from '../InterpolatedText';

export function isContentfulBladeNetworkStats(
  blade: RelatedBlade
): blade is ContentfulBladeNetworkStats {
  return blade.contentTypeId === 'bladeNetworkStats';
}

interface BladeNetworkStatsProps {
  blade: ContentfulBladeNetworkStats;
}

export const BladeNetworkStats: React.FC<BladeNetworkStatsProps> = ({ blade }) => {
  const stackedQuote = blade.variant === 'stacked' && !!blade.quotearticle;
  const inlineQuote = blade.variant === 'inline' && !!blade.quotearticle;
  const inlineNoQuote = blade.variant === 'inline' && !blade.quotearticle;

  return (
    <Div id={blade.htmlId} backgroundColor={blade.backgroundColor}>
      <Container>
        {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
          <Divider paddingVertical={0} />
        )}
        <Div {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}>
          <Row>
            <Col lg={{ span: stackedQuote ? 8 : 12, offset: stackedQuote ? 2 : 0 }}>
              <Row>
                {blade.title && (
                  <Col marginBottom={[6, 6, 6, 7]} lg={inlineNoQuote ? 5 : 7}>
                    <Text variant="headline2" color="black">
                      {blade.title}
                    </Text>
                    {blade.learnMoreLink && (
                      <ContentfulLink link={blade.learnMoreLink} marginTop={4} />
                    )}
                    {blade.learnMoreUrl && (
                      <LocaleLink
                        to={blade.learnMoreUrl}
                        border="none"
                        className="learn-more"
                        display="flex"
                        marginTop={4}
                        fontSize={3}
                        alignItems="center"
                      >
                        <Span marginRight={0} color="blue1" fontWeight={7}>
                          {blade.learnMoreText}
                        </Span>
                      </LocaleLink>
                    )}
                  </Col>
                )}
                <Col lg={inlineNoQuote ? 7 : 12}>
                  <Row>
                    <Col lg={inlineQuote ? 4 : 12}>
                      <Row>
                        {blade.statsFeatures?.map((stat, index) => (
                          <Col
                            key={`${blade.contentfulId}-${stat.contentfulId}-${index}`}
                            marginBottom={6}
                            md={4}
                            lg={inlineQuote ? 12 : 4}
                          >
                            <Text
                              wordBreak="break-all"
                              variant="stat"
                              marginBottom={1}
                              color="orange0"
                            >
                              <InterpolatedText text={stat.title} />
                            </Text>
                            {stat.description && (
                              <MarkdownBlock
                                source={stat.description}
                                renderers={{
                                  strong: ({ children }) => (
                                    <Text variant="body2-bold" color="black" tag="Span">
                                      {children}
                                    </Text>
                                  ),
                                  paragraph: ({ children }) => (
                                    <Text
                                      variant="body2"
                                      color="black"
                                      marginTop={0}
                                      marginBottom={0}
                                      tag="P"
                                    >
                                      {children}
                                    </Text>
                                  ),
                                }}
                              />
                            )}
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    {blade.quotearticle && (
                      <Col lg={{ span: inlineQuote ? 7 : 12, offset: inlineQuote ? 1 : 0 }}>
                        <Row>
                          <QuoteSection fullwidth blade={blade.quotearticle} />
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Div>
        {(blade.bladeBorder === 'border-bottom' || blade.bladeBorder === 'border') && (
          <Divider paddingVertical={0} />
        )}
      </Container>
    </Div>
  );
};
