import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Div, H3 } from '../Elements';
import { Divider } from '../Divider';
import { MarkdownBlock } from '../MarkdownBlock';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulBladeKeyFeatures } from '../../../contentful/content-types/blades/blade-key-features';
// import { ContentfulBladeKeyFeatures } from '../../../contentful/content-types/blades/blade-key-features';
import sectionPadding from '../BladePadding';
import { Lazy } from '../Lazy';
import Text from '../Text/Text';

export function isContentfulBladeKeyFeatures(
  blade: RelatedBlade
): blade is ContentfulBladeKeyFeatures {
  return blade.contentTypeId === 'bladeKeyFeatures';
}

interface KeyFeaturesBladeProps {
  blade: ContentfulBladeKeyFeatures;
}

const getClassnameFromBackgroundColor = new Map([
  ['white', 'bg-white'],
  ['light-blue', 'bg-blue5'],
]);

export const KeyFeaturesBlade: React.FC<KeyFeaturesBladeProps> = ({ blade }) => {
  return (
    <Div className={`body-1 ${getClassnameFromBackgroundColor.get(blade.backgroundColor) || ''}`}>
      <Container>
        {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
          <Divider paddingVertical={0} paddingBottom={3} />
        )}
        <Div {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}>
          <Row>
            <Col lg={blade.variant === 'inline' ? 4 : 12}>
              <Row>
                <Col xs={12}>
                  {blade.title && (
                    <H3 color={blade.titleColor} paddingBottom={blade.variant === 'inline' ? 2 : 3}>
                      {blade.title}
                    </H3>
                  )}
                  {blade.description && (
                    <MarkdownBlock
                      source={blade.description}
                      renderers={{
                        paragraph: ({ children }) => (
                          <Text marginBottom={3} variant="body2">
                            {children}
                          </Text>
                        ),
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg={blade.variant === 'inline' ? 8 : 12}>
              <Row>
                {blade.keyFeature.map((feature: string, index: number) => {
                  return (
                    <React.Fragment key={`${index + blade.contentTypeId}-keyfeature`}>
                      <Col sm={5} xs={12} className={'pb3'}>
                        <div className="flex items-start">
                          <Lazy flexShrink={0}>
                            <img
                              className="mr2"
                              style={{ marginTop: '4px' }}
                              alt="cloudflare-checkmark"
                              src="/img/icons/orange/checkmark.svg"
                            />
                          </Lazy>
                          <MarkdownBlock
                            marginVertical={0}
                            display="inline"
                            renderers={{
                              paragraph: ({ children }) => (
                                <Text
                                  marginVertical={0}
                                  variant={blade.featuresTextStyle || 'body2'}
                                >
                                  {children}
                                </Text>
                              ),
                            }}
                            source={feature}
                          />
                        </div>
                      </Col>
                      <Col sm={1}></Col>
                    </React.Fragment>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Div>
        {(blade.bladeBorder === 'border-bottom' || blade.bladeBorder === 'border') && (
          <Divider paddingVertical={0} />
        )}
      </Container>
    </Div>
  );
};

export default KeyFeaturesBlade;
