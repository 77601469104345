import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Div, P, Span, H4, H5, A, Col, H3 } from '../Elements';
import { Row, Container } from 'react-bootstrap';
import { Icon } from '../Icon';
import sectionPadding from '../BladePadding';
import MediaContainer, { Size } from '../MediaContainer';
import {
  ContentfulBladeTable,
  TableCell,
  TableRow,
  TableSection,
} from '../../../contentful/content-types/blades/blade-table/blade-table.interface';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulAssetFile } from '../ContentfulAssetFile/ContentfulAssetFile';
import { Color } from '../Elements/types';
import { Divider } from '../Divider';
import { InterpolatedText } from '../InterpolatedText';
import { useTranslations } from '../../../common/hooks/useTranslations';
import { LocaleLink } from '../links/LocaleLink';
interface BladeTableProps {
  blade: ContentfulBladeTable;
}

export const getClassnameFromBackgroundColor = new Map([
  ['light-blue', 'bg-blue5'],
  ['light-gray', 'bg-gray4'],
  ['white', 'white'],
]);

const useScrollListener = (element: any, handleScroll: any) => {
  const listenToScroll = React.useCallback(
    e => {
      requestAnimationFrame(() => handleScroll(e));
    },
    [handleScroll]
  );

  React.useEffect(() => {
    const currentElement = element.current;
    if (currentElement) {
      currentElement.addEventListener('scroll', listenToScroll);
    }
    return () => {
      currentElement?.removeEventListener('scroll', listenToScroll);
    };
  }, [element]);
};

const MobileTableScrollingHint: React.FC<{ size: Size }> = ({ size }) => (
  <Div
    display={
      size.xs || (size.md && !size.lg) || (!size.xs && size.sm && !size.md) ? 'flex' : 'none'
    }
    flexDirection="row"
    style={{
      position: 'sticky',
      top: 'calc(100% - 43px)',
      zIndex: 11,
      margin: `0  calc((100vw - 100%)/-2)`,
      overflowX: 'hidden',
    }}
    backgroundColor="blue4"
    alignItems="center"
    justifyContent="center"
    paddingVertical={1}
  >
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1194 7.49217L13.4729 6.63867L8.34788 1.51367L7.64062 1.51367L2.51562 6.63867L2.86937 7.49217L13.1194 7.49217ZM7.99438 2.57417L11.9119 6.49217L4.07637 6.49217L7.99438 2.57417Z"
        fill="#222222"
      />
      <path
        d="M13.1214 8.50617L2.87138 8.50617L2.51787 9.35967L7.64287 14.4847L8.34988 14.4847L13.4749 9.35967L13.1214 8.50617ZM7.99638 13.4242L4.07838 9.50617L11.9141 9.50617L7.99638 13.4242Z"
        fill="#222222"
      />
    </svg>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.50978 13.1213L9.36328 13.4748L14.4883 8.34983L14.4883 7.64258L9.36328 2.51758L8.50978 2.87133L8.50978 13.1213ZM13.4278 7.99633L9.50978 11.9138L9.50978 4.07833L13.4278 7.99633Z"
        fill="#222222"
      />
      <path
        d="M7.49578 13.1233L7.49578 2.87333L6.64228 2.51983L1.51728 7.64483L1.51728 8.35183L6.64228 13.4768L7.49578 13.1233ZM2.57778 7.99833L6.49578 4.08033L6.49578 11.9161L2.57778 7.99833Z"
        fill="#222222"
      />
    </svg>
    <P color="black" marginLeft={1} marginVertical={0}>
      {useTranslations()('Scroll_to_view_more')}
    </P>
  </Div>
);

export const isContentfulBladeTable = (blade: RelatedBlade): blade is ContentfulBladeTable =>
  blade.contentTypeId === 'bladeTable';
export const BladeTable: React.FC<BladeTableProps> = ({ blade }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const tableRef = useRef<HTMLDivElement>(null);
  // useScrollListener(tableRef, (e: any) => handleCurrentScroll(e));

  const handleCurrentScroll = (e: any) => {
    setScrollPosition(Math.round((e.target.scrollLeft / e.target.clientWidth) * 100));
  };
  return (
    <div className={getClassnameFromBackgroundColor.get(blade.backgroundColor || 'white')}>
      {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
        <Divider paddingVertical={0} className="container" />
      )}
      <Container className="table__container">
        <Div {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}>
          <MediaContainer>
            {(size: Size) => (
              <>
                <MobileTableScrollingHint size={size} />
                <Div>
                  {blade.sectionTitle && (
                    <h4 className="body-1 orange mb1">{blade.sectionTitle}</h4>
                  )}
                  <H3>{blade.title}</H3>
                  <P className="body-1" style={{ maxWidth: '512px' }} marginBottom={8}>
                    <InterpolatedText text={blade.copy} />
                  </P>
                </Div>
                <div
                  ref={tableRef}
                  style={{
                    overflowX:
                      size.xs || (size.md && !size.lg) || (!size.xs && size.sm && !size.md)
                        ? scrollPosition < 120
                          ? 'scroll'
                          : 'hidden'
                        : 'hidden',
                    paddingBottom: '75px',
                  }}
                >
                  <Div
                    color="black"
                    style={{
                      width: `${
                        (!size.xs && size.sm && size.md && !size.lg) || (size.sm && !size.md)
                          ? '150%'
                          : size.xs && !size.sm
                          ? '240%'
                          : '100%'
                      } `,
                    }}
                  >
                    {blade?.groupsInTable && (
                      <>
                        <Div marginBottom={size.md ? 10 : 0}>
                          {blade?.groupsInTable?.map((section: TableSection) => (
                            <React.Fragment key={section.sectionHeader}>
                              <Row key={section.sectionHeader}>
                                {blade?.columnHeaders?.map((header: TableCell) => (
                                  <Col
                                    lg={Math.floor(
                                      9 / Math.floor(blade?.columnHeaders?.length || 3)
                                    )}
                                    md={Math.floor(
                                      9 / Math.floor(blade?.columnHeaders?.length || 3)
                                    )}
                                    xs={Math.floor(
                                      9 / Math.floor(blade?.columnHeaders?.length || 3)
                                    )}
                                    key={header.cellText}
                                    className={`${
                                      size.xs ||
                                      (size.md && !size.lg) ||
                                      (!size.xs && size.sm && !size.md)
                                        ? 'sticky-column'
                                        : ''
                                    }`}
                                  >
                                    <Div>
                                      {header.cellText && (
                                        <H4
                                          color={header.cellTextLinkUrl ? 'blue0' : 'orange0'}
                                          marginBottom={1}
                                        >
                                          {header.cellTextLinkUrl ? (
                                            <LocaleLink to={header.cellTextLinkUrl} fontWeight={6}>
                                              {header.cellText}
                                            </LocaleLink>
                                          ) : (
                                            header.cellText
                                          )}
                                        </H4>
                                      )}
                                      {header.cellSubtitle && (
                                        <H5
                                          margin={0}
                                          fontWeight={6}
                                          marginBottom={3}
                                          className="nowrap"
                                        >
                                          {header.cellSubtitle}
                                        </H5>
                                      )}
                                    </Div>
                                  </Col>
                                ))}
                                <Div
                                  className="divider"
                                  color="gray2"
                                  border="top"
                                  marginTop={3}
                                  width="100%"
                                ></Div>
                              </Row>
                              {section.sectionHeader && (
                                <>
                                  <H5
                                    margin={0}
                                    fontWeight={6}
                                    marginTop={9}
                                    marginBottom={3}
                                    className="wrap"
                                    color="orange1"
                                  >
                                    {section.sectionHeader}
                                  </H5>
                                  <Div
                                    className="divider"
                                    color="gray2"
                                    border="top"
                                    marginTop={5}
                                    width="100%"
                                  ></Div>
                                </>
                              )}

                              {section?.rowsInHeader?.map((row: TableRow) => (
                                <Row className="b--gray2 bb" key={row.contentfulId}>
                                  <>
                                    <Col
                                      lg={3}
                                      md={3}
                                      xs={2}
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      className={`${
                                        size.xs ||
                                        (size.md && !size.lg) ||
                                        (!size.xs && size.sm && !size.md)
                                          ? 'sticky-column'
                                          : ''
                                      }`}
                                    >
                                      <Div
                                        display={'flex'}
                                        justifyContent={'between'}
                                        alignItems="center"
                                        paddingTop={[1, 3]}
                                        paddingBottom={[1, 3]}
                                        position="relative"
                                      >
                                        {row.rowHeader2?.cellText && (
                                          <P
                                            fontSize={2}
                                            fontWeight={4}
                                            margin={0}
                                            lineHeight="copy"
                                          >
                                            {row.rowHeader2.cellTextLinkUrl ? (
                                              <LocaleLink
                                                to={row.rowHeader2.cellTextLinkUrl}
                                                fontWeight={6}
                                              >
                                                {row.rowHeader2.cellText}
                                              </LocaleLink>
                                            ) : (
                                              row.rowHeader2.cellText
                                            )}
                                          </P>
                                        )}
                                      </Div>
                                    </Col>
                                    {row?.cellsInRow?.map((cell, index) => (
                                      <Col
                                        lg={Math.floor(9 / (row?.cellsInRow?.length || 3))}
                                        md={Math.floor(9 / (row?.cellsInRow?.length || 3))}
                                        xs={Math.floor(9 / (row?.cellsInRow?.length || 3))}
                                        display="flex"
                                        alignItems="center"
                                        key={cell.contentfulId}
                                      >
                                        <Div
                                          display={'flex'}
                                          justifyContent={'between'}
                                          alignItems="center"
                                          paddingTop={[2, 3]}
                                          paddingBottom={[1, 3]}
                                          position="relative"
                                        >
                                          <Div
                                            display={'flex'}
                                            flexDirection="column"
                                            alignItems="center"
                                          >
                                            {cell.cellText && (
                                              <P
                                                fontSize={2}
                                                fontWeight={6}
                                                margin={0}
                                                lineHeight="copy"
                                              >
                                                {cell.cellTextLinkUrl ? (
                                                  <LocaleLink
                                                    to={cell.cellTextLinkUrl}
                                                    fontWeight={6}
                                                  >
                                                    {cell.cellText}
                                                  </LocaleLink>
                                                ) : (
                                                  cell.cellText
                                                )}
                                              </P>
                                            )}

                                            {cell.commonElements && (
                                              <Icon
                                                type={
                                                  cell.commonElements === 'Check'
                                                    ? 'checkmark'
                                                    : 'markX'
                                                }
                                                fill="orange1"
                                                className={
                                                  cell.commonElements === 'Check'
                                                    ? 'orange1'
                                                    : 'gray1'
                                                }
                                              />
                                            )}
                                            {cell.imageAssetFile && (
                                              <ContentfulAssetFile
                                                className="tc w-100 w-80-l pa3 db center"
                                                assetFile={cell.imageAssetFile}
                                                fallBackUrl={
                                                  cell.imageAssetFile?.contentfulAsset?.file
                                                    ?.publicURL
                                                }
                                              />
                                            )}
                                          </Div>
                                        </Div>
                                      </Col>
                                    ))}
                                  </>
                                </Row>
                              ))}
                            </React.Fragment>
                          ))}
                        </Div>
                      </>
                    )}
                    {blade.rowsInTable && (
                      <>
                        <Row
                          style={{
                            top:
                              (!size.xs && size.sm && size.md && !size.lg) ||
                              (size.xs && !size.md) ||
                              (size.sm && !size.md)
                                ? 70
                                : 101,
                            zIndex: 9,
                          }}
                        >
                          <Col
                            lg={3}
                            md={3}
                            xs={2}
                            className={`${
                              size.xs || (size.md && !size.lg) || (!size.xs && size.sm && !size.md)
                                ? 'sticky-column'
                                : ''
                            }`}
                          >
                            {blade.displayHeader && (
                              <Div
                                display="flex"
                                flexDirection="column"
                                justifyContent="end"
                                style={{ height: '100%' }}
                              >
                                <Div style={{ paddingRight: size.md ? '35px' : 0 }}>
                                  <P
                                    fontSize={2}
                                    fontWeight={6}
                                    lineHeight="copy"
                                    margin={0}
                                    marginVertical={1}
                                  >
                                    {blade.displayHeader}
                                  </P>
                                </Div>
                              </Div>
                            )}
                          </Col>

                          {blade?.columnHeaders?.map((header: TableCell) => (
                            <Col
                              lg={Math.floor(9 / Math.floor(blade?.columnHeaders?.length || 3))}
                              md={Math.floor(9 / Math.floor(blade?.columnHeaders?.length || 3))}
                              xs={Math.floor(9 / Math.floor(blade?.columnHeaders?.length || 3))}
                              key={header.contentfulId}
                            >
                              <Div
                                display="flex"
                                flexDirection="column"
                                style={{ height: '100%' }}
                                justifyContent="between"
                              >
                                {header.cellText &&
                                  (size.xs && !size.sm ? (
                                    <H5
                                      color={header.cellTextLinkUrl ? 'blue0' : 'orange0'}
                                      marginBottom={1}
                                    >
                                      {header.cellTextLinkUrl ? (
                                        <LocaleLink to={header.cellTextLinkUrl} fontWeight={6}>
                                          {header.cellText}
                                        </LocaleLink>
                                      ) : (
                                        header.cellText
                                      )}
                                    </H5>
                                  ) : (
                                    <H4
                                      color={header.cellTextLinkUrl ? 'blue0' : 'orange0'}
                                      marginBottom={1}
                                    >
                                      {header.cellTextLinkUrl ? (
                                        <LocaleLink to={header.cellTextLinkUrl} fontWeight={6}>
                                          {header.cellText}
                                        </LocaleLink>
                                      ) : (
                                        header.cellText
                                      )}
                                    </H4>
                                  ))}
                                {header.cellSubtitle &&
                                  (size.xs && !size.sm ? (
                                    <P
                                      margin={0}
                                      fontWeight={6}
                                      marginBottom={3}
                                      className="nowrap body-2"
                                    >
                                      {header.cellSubtitle}
                                    </P>
                                  ) : (
                                    <H5
                                      margin={0}
                                      fontWeight={6}
                                      marginBottom={3}
                                      className="nowrap"
                                    >
                                      {header.cellSubtitle}
                                    </H5>
                                  ))}
                              </Div>
                            </Col>
                          ))}
                          <Div
                            className="divider"
                            color="gray2"
                            border="top"
                            marginTop={3}
                            width="100%"
                          ></Div>
                        </Row>
                        <Div marginBottom={size.md ? 10 : 0}>
                          {blade?.rowsInTable?.map((row: TableRow) => (
                            <Row className="b--gray2 bb" key={row.contentfulId}>
                              <>
                                <Col
                                  lg={3}
                                  md={3}
                                  xs={2}
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="center"
                                  className={`${
                                    size.xs ||
                                    (size.md && !size.lg) ||
                                    (!size.xs && size.sm && !size.md)
                                      ? 'sticky-column'
                                      : ''
                                  }`}
                                >
                                  <Div
                                    display={'flex'}
                                    justifyContent={'between'}
                                    alignItems="center"
                                    paddingTop={[1, 3]}
                                    paddingBottom={[1, 3]}
                                  >
                                    {row.rowHeader2?.cellText && (
                                      <P fontSize={2} fontWeight={4} margin={0} lineHeight="copy">
                                        {row.rowHeader2.cellTextLinkUrl ? (
                                          <LocaleLink
                                            to={row.rowHeader2.cellTextLinkUrl}
                                            fontWeight={6}
                                          >
                                            {row.rowHeader2.cellText}
                                          </LocaleLink>
                                        ) : (
                                          row.rowHeader2.cellText
                                        )}
                                      </P>
                                    )}
                                  </Div>
                                </Col>
                                {row?.cellsInRow?.map(cell => (
                                  <Col
                                    lg={Math.floor(9 / (row?.cellsInRow?.length || 3))}
                                    md={Math.floor(9 / (row?.cellsInRow?.length || 3))}
                                    xs={Math.floor(9 / (row?.cellsInRow?.length || 3))}
                                    display="flex"
                                    alignItems="center"
                                    key={cell.contentfulId}
                                  >
                                    <Div
                                      display={'flex'}
                                      justifyContent={'between'}
                                      alignItems="center"
                                      paddingTop={[2, 3]}
                                      paddingBottom={[1, 3]}
                                      position="relative"
                                    >
                                      <Div
                                        display={'flex'}
                                        flexDirection="column"
                                        alignItems="center"
                                      >
                                        {cell.cellText && (
                                          <P
                                            fontSize={2}
                                            fontWeight={6}
                                            margin={0}
                                            lineHeight="copy"
                                          >
                                            {cell.cellTextLinkUrl ? (
                                              <LocaleLink to={cell.cellTextLinkUrl} fontWeight={6}>
                                                {cell.cellText}
                                              </LocaleLink>
                                            ) : (
                                              cell.cellText
                                            )}
                                          </P>
                                        )}

                                        {cell.commonElements && (
                                          <Icon
                                            type={
                                              cell.commonElements === 'Check'
                                                ? 'checkmark'
                                                : 'markX'
                                            }
                                            fill="orange1"
                                            className={
                                              cell.commonElements === 'Check' ? 'orange1' : 'gray1'
                                            }
                                          />
                                        )}
                                        {cell.imageAssetFile && (
                                          <ContentfulAssetFile
                                            className="tc w-100 w-80-l db left"
                                            assetFile={cell.imageAssetFile}
                                            fallBackUrl={
                                              cell.imageAssetFile.contentfulAsset?.file?.publicURL
                                            }
                                          />
                                        )}
                                      </Div>
                                    </Div>
                                  </Col>
                                ))}
                              </>
                            </Row>
                          ))}
                        </Div>
                      </>
                    )}
                  </Div>
                </div>
              </>
            )}
          </MediaContainer>
        </Div>
      </Container>
      {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
        <Divider paddingVertical={0} className="container" />
      )}
    </div>
  );
};
