import React from 'react';
import Modal from 'react-modal';
import sectionPadding from '../BladePadding';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';
import { RelatedBlade } from '../../../contentful/blades';
import {
  ContentfulBladePlansMatrix,
  ContentfulPlanElementPlan,
} from '../../../contentful/content-types/blades/blade-plans-matrix';
import { ContentfulButton } from '../buttons/ContentfulButton';
import { CFXExit } from '../CFIcons/CFXExit';
import { Divider } from '../Divider';
import { Div, Container, Row, Col, Label, Button, Span } from '../Elements';
import { StyleSystemProps } from '../Elements/Elements';
import { Icon } from '../Icon';
import { ContentfulLink } from '../links/ContentfulLink';
import { MarkdownBlock } from '../MarkdownBlock';
import { SelectDropdown } from '../SelectDropdown';
import Text from '../Text/Text';
import { TextVariantMap } from '../Text/Text.interface';
import { Tooltip } from './Tooltip';
import { EqualHeight, EqualHeightElement } from '../EqualHeight';
import MediaContainer from '../MediaContainer';

interface BladePricingMatrixProps {
  blade: ContentfulBladePlansMatrix;
  isModal?: boolean;
}

export function isBladePlansMatrix(blade: RelatedBlade): blade is ContentfulBladePlansMatrix {
  return blade.contentTypeId === 'bladePlansMatrix';
}

export const BladePlansMatrix: React.FC<BladePricingMatrixProps> = ({ blade, isModal }) => {
  const focused: Pick<
    StyleSystemProps,
    'borderColor' | 'outlineColor' | 'outlineStyle' | 'outlineWidth' | 'outlineInnerOffset'
  > = {
    outlineWidth: 2,
    outlineStyle: 'solid',
    outlineColor: 'blue2',
    outlineInnerOffset: 2,
    borderColor: 'transparent',
  };
  const [selectedGroup, setSelectedGroup] = React.useState<string>('');
  const [selectedPlan, setSelectedPlan] = React.useState<ContentfulPlanElementPlan | null>(
    blade.plans?.length ? blade.plans[0]?.plan || null : null
  );

  const filteredGroups = selectedGroup
    ? blade.planFeaturesGroup?.filter(group => group.contentfulId === selectedGroup)
    : blade.planFeaturesGroup;

  const staticStrings = useStaticStrings();

  const isCards = blade.mobileMode === 'Cards';

  const padding = sectionPadding(blade.sectionPadding, blade.contentTypeId);

  return (
    <MediaContainer>
      {size => (
        <EqualHeight>
          <Div backgroundColor="white" paddingBottom={padding.paddingBottom}>
            <Container>
              <Div
                className="top-0"
                paddingTop={9}
                backgroundColor="white"
                zIndex={4}
                position={isModal ? 'sticky' : 'static'}
                display={[isCards ? 'none' : 'block', 'none']}
              >
                <Div display="flex">
                  {blade.plans?.map(plan => (
                    <Button
                      marginRight={3}
                      padding={0}
                      border="bottom"
                      borderColor={
                        selectedPlan?.contentfulId === plan?.plan?.contentfulId
                          ? 'orange0'
                          : 'transparent'
                      }
                      borderWidth={2}
                      onClick={() => setSelectedPlan(plan?.plan || null)}
                      backgroundColor="transparent"
                      key={plan.contentfulId}
                    >
                      {plan?.plan?.name && (
                        <Text variant="body1-bold" color="orange0">
                          {plan?.plan?.name}
                        </Text>
                      )}
                    </Button>
                  ))}
                </Div>
                <Divider display={['block', 'block', 'block', 'none']} paddingVertical={3} />
              </Div>
              <Row
                className="top-0"
                paddingTop={padding.paddingTop}
                backgroundColor="white"
                zIndex={4}
                position={isModal ? ['static', 'sticky'] : 'static'}
              >
                <Col lg={4}>
                  {blade.title && (
                    <Row>
                      <Col lg={9}>
                        <Text marginBottom={[5, 5, 5, 0]} variant="headline3">
                          {blade.title}
                        </Text>
                      </Col>
                    </Row>
                  )}
                  {blade.hasFilter && (
                    <Div
                      display="flex"
                      heightPercentage={100}
                      flexDirection="column"
                      justifyContent="end"
                    >
                      <Row>
                        <Col md={4} lg={9}>
                          <Label htmlFor="table-filter">
                            <Text marginBottom={1} variant="body2-bold">
                              {blade.filterLabel}
                            </Text>
                            <SelectDropdown
                              id="table-filter"
                              value={selectedGroup}
                              styleprops={{
                                width: '100%',
                                paddingVertical: 1,
                                ...TextVariantMap.body2,
                                focused,
                              }}
                              title="table-filter"
                              name="table-filter"
                              data-key="table-filter"
                              onChange={e => setSelectedGroup((e.target as any).value)}
                            >
                              <option value="">{staticStrings.json_values.View_All}</option>
                              {blade.planFeaturesGroup?.map((featureGroup, i) => (
                                <option
                                  key={featureGroup.contentfulId}
                                  value={featureGroup.contentfulId}
                                >
                                  {featureGroup.name}
                                </option>
                              ))}
                            </SelectDropdown>
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <Divider
                            display={['block', 'block', 'block', 'none']}
                            paddingBottom={2}
                            paddingTop={3}
                          />
                        </Col>
                      </Row>
                    </Div>
                  )}
                </Col>
                <Col lg={8}>
                  <Div display={[isCards ? 'block' : 'none', 'block']}>
                    <Row>
                      {blade.plans?.map(plan => (
                        <Col
                          marginBottom={[7, 0]}
                          sm={Math.floor(12 / (blade?.plans?.length || 1))}
                          key={plan.contentfulId}
                        >
                          <EqualHeightElement disable={size.xs} name="top-pricing">
                            {plan?.plan?.name && (
                              <Text
                                marginBottom={plan.showDescription ? 2 : '4px'}
                                variant={plan.showDescription ? 'headline3' : 'headline5'}
                                color="orange0"
                                wordBreak="break-all"
                              >
                                {plan?.plan?.name}
                              </Text>
                            )}
                            {plan.showDescription && (
                              <MarkdownBlock
                                source={plan.plan?.description}
                                renderers={{
                                  paragraph: ({ children }) => (
                                    <Text variant="body2">{children}</Text>
                                  ),
                                }}
                              />
                            )}
                          </EqualHeightElement>
                          {plan.showDescription && <Divider paddingVertical={3} />}
                          <EqualHeightElement disable={size.xs} name="middle-pricing">
                            {!!plan?.plan?.customPricingText && (
                              <Text
                                marginBottom={plan.showPriceDescription ? 1 : 2}
                                variant={plan.showPriceDescription ? 'headline4' : 'body1-bold'}
                                color="black"
                                wordBreak="break-all"
                              >
                                {plan?.plan?.customPricingText}
                              </Text>
                            )}
                            {plan.showPriceDescription && (
                              <Text marginBottom={2} variant="body2" color="gray0">
                                {plan?.plan?.pricingDescription}
                              </Text>
                            )}
                          </EqualHeightElement>

                          {plan.callToAction && <ContentfulButton button={plan.callToAction} />}
                          {plan.learnMoreLink && <Divider paddingVertical={3} />}
                          {plan.learnMoreLink && <ContentfulLink link={plan.learnMoreLink} />}
                        </Col>
                      ))}
                    </Row>
                  </Div>
                </Col>
              </Row>
              {blade.plans &&
                blade.planFeatures?.map((feature, index) => (
                  <Div display={[isCards ? 'none' : 'block', 'block']} key={feature.contentfulId}>
                    <Row>
                      <Col lg={12}>
                        <Row>
                          <Col lg={12}>
                            <Divider paddingTop={index === 0 ? 3 : 0} paddingVertical={0} />
                          </Col>
                        </Row>
                        <Row alignItems="center" paddingVertical={2}>
                          <Col lg={4}>
                            <Div display="flex" alignItems="center" justifyContent="between">
                              <Text variant="body2" marginBottom={[2, 2, 2, 0]}>
                                {feature.name}
                              </Text>
                              {feature?.name && feature?.description && (
                                <Tooltip
                                  title={feature?.name || ''}
                                  description={feature?.description || ''}
                                />
                              )}
                            </Div>
                          </Col>
                          <Col lg={8}>
                            <Row>
                              {blade.plans?.map(plan => {
                                const planValue = feature.planValues?.find(
                                  value => value.plan?.contentfulId === plan.plan?.contentfulId
                                );
                                const isSelectedPlan =
                                  selectedPlan?.contentfulId === plan?.plan?.contentfulId;
                                const displayValue = isSelectedPlan ? 'flex' : 'none';
                                return (
                                  <Col
                                    display={[displayValue, 'flex']}
                                    sm={Math.floor(12 / (blade?.plans?.length || 1))}
                                    key={plan.contentfulId}
                                  >
                                    {planValue?.contentTypeId === 'planElementFeatureValueText' && (
                                      <Text variant="body2-bold">
                                        {planValue?.textValue || planValue?.value}
                                      </Text>
                                    )}
                                    {planValue?.contentTypeId ===
                                      'planElementFeatureValueBoolean' && (
                                      <>
                                        {planValue.value ? (
                                          <Icon type="checkmark" color="orange0" />
                                        ) : (
                                          <Icon type="markX" color="gray2" />
                                        )}
                                      </>
                                    )}
                                  </Col>
                                );
                              })}
                            </Row>
                          </Col>
                        </Row>
                        {blade?.planFeatures?.length && index === blade?.planFeatures?.length - 1 && (
                          <Row>
                            <Col lg={12}>
                              <Divider paddingVertical={0} />
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Div>
                ))}
              {filteredGroups?.map((featureGroup, index) => (
                <Div
                  display={[isCards ? 'none' : 'block', 'block']}
                  key={featureGroup.contentfulId}
                >
                  {index === 0 && (
                    <Row>
                      <Col lg={12}>
                        <Divider
                          display={['none', 'none', 'none', 'block']}
                          paddingTop={3}
                          paddingVertical={0}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row marginTop={7}>
                    <Col lg={12}>
                      {featureGroup.name && (
                        <Text marginBottom={3} variant="headline5" color="orange0">
                          {featureGroup.name}
                        </Text>
                      )}
                    </Col>
                    {blade.plans &&
                      featureGroup.features?.map((feature, index) => (
                        <Col key={feature.contentfulId} lg={12}>
                          <Row>
                            <Col lg={12}>
                              <Divider paddingVertical={0} />
                            </Col>
                          </Row>
                          <Row alignItems="center" paddingVertical={2}>
                            <Col lg={4}>
                              <Div display="flex" alignItems="center" justifyContent="between">
                                <Text variant="body2" marginBottom={[2, 2, 2, 0]}>
                                  {feature.name}
                                </Text>
                                {feature?.name && feature?.description && (
                                  <Tooltip
                                    title={feature?.name || ''}
                                    description={feature?.description || ''}
                                  />
                                )}
                              </Div>
                            </Col>
                            <Col lg={8}>
                              <Row>
                                {blade.plans?.map(plan => {
                                  const planValue = feature.planValues?.find(
                                    value => value.plan?.contentfulId === plan.plan?.contentfulId
                                  );
                                  const isSelectedPlan =
                                    selectedPlan?.contentfulId === plan?.plan?.contentfulId;
                                  const displayValue = isSelectedPlan ? 'flex' : 'none';
                                  return (
                                    <Col
                                      display={[displayValue, 'flex']}
                                      sm={Math.floor(12 / (blade?.plans?.length || 1))}
                                      key={plan.contentfulId}
                                    >
                                      {planValue?.contentTypeId ===
                                        'planElementFeatureValueText' && (
                                        <Text variant="body2-bold">
                                          {planValue?.textValue || planValue?.value}
                                        </Text>
                                      )}
                                      {planValue?.contentTypeId ===
                                        'planElementFeatureValueBoolean' && (
                                        <>
                                          {planValue.value ? (
                                            <Icon type="checkmark" color="orange0" />
                                          ) : (
                                            <Icon type="markX" color="gray2" />
                                          )}
                                        </>
                                      )}
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                          </Row>
                          {featureGroup?.features?.length &&
                            index === featureGroup?.features?.length - 1 && (
                              <Row>
                                <Col lg={12}>
                                  <Divider paddingVertical={0} />
                                </Col>
                              </Row>
                            )}
                        </Col>
                      ))}
                  </Row>
                </Div>
              ))}
            </Container>
          </Div>
        </EqualHeight>
      )}
    </MediaContainer>
  );
};

export const ModalPlansMatrix: React.FC<BladePricingMatrixProps> = ({ blade }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <Div paddingTop={[4, 7]} paddingBottom={[7, 10]}>
      <Container>
        <Row>
          <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }}>
            <Button
              display="flex"
              lineHeight={0}
              paddingVertical={2}
              className="pointer"
              backgroundColor="transparent"
              border="all"
              color="blue1"
              width="100%"
              borderColor="blue1"
              borderWidth={2}
              onClick={() => setOpen(true)}
              alignItems="center"
              justifyContent="center"
            >
              <Text tag="Span" variant="learnmore" color="blue1">
                {blade.modalButtonText}
              </Text>
            </Button>
          </Col>
        </Row>

        <Modal
          closeTimeoutMS={200}
          isOpen={open}
          className="bg-white h-auto min-h-100 outline-0"
          overlayClassName="fixed top-0 bottom-0 left-0 right-0 bg--grayOpaque z-max overflow-y-scroll"
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
          shouldFocusAfterRender={false}
        >
          <Div position="relative">
            <Span zIndex={5} className="right-1 top-2" width={4} position="fixed">
              <Button
                onClick={() => setOpen(false)}
                lineHeight={0}
                className="pointer"
                padding={0}
                backgroundColor="transparent"
                border="none"
              >
                <CFXExit size={24} color="black" />
              </Button>
            </Span>
            <BladePlansMatrix isModal blade={blade} />
          </Div>
        </Modal>
      </Container>
    </Div>
  );
};
