import React, { useState, useEffect, ReactType } from 'react';
import { Div, Container, H3, Col, Span } from '../Elements';
import { ContentfulBladeAccordion } from '../../../contentful/content-types/blades/blade-accordion/blade-accordion.interface';
import { RelatedBlade } from '../../../contentful/blades';
import sectionPadding from '../BladePadding';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { ContentfulRichTextElement } from '../../../contentful/content-types/blades/blade-richtext-columns';
import { RichTextRenderer } from '../RichTextRenderer';
import { richTextOptions } from '../RichTextRenderer/richTextOptions';
import { MarkdownBlock } from '../MarkdownBlock';
import Text from '../Text/Text';
import { LocaleLink } from '../links/LocaleLink';
import { TextVariantMap } from '../Text/Text.interface';
import { Icon } from '../Icon';
interface AccordionBladeProps {
  blade: ContentfulBladeAccordion;
}

const markDownOptions: { [nodeType: string]: ReactType } = {
  paragraph: ({ children }) => (
    <Text marginTop={3} variant="body1">
      {children}
    </Text>
  ),
  link: ({ children, href }) => (
    <LocaleLink color="blue1" className="learn-more" {...TextVariantMap['learnmore']} to={href}>
      {children}
    </LocaleLink>
  ),
};

export const getClassnameFromBackgroundColor = new Map([
  ['blue', 'bg-blue0'],
  ['white', 'bg-white'],
  ['light-blue', 'bg-blue5'],
]);

export function isAccordionBlade(blade: RelatedBlade): blade is ContentfulBladeAccordion {
  return blade.contentTypeId === 'bladeAccordion';
}

export const AccordionBlade: React.FC<AccordionBladeProps> = ({ blade }) => {
  const [mouseDown, setMouseDown] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', e => {
      setMouseDown(true);
    });
    document.addEventListener('keydown', e => {
      setMouseDown(false);
    });
  }, []);
  return (
    <Div
      id={blade.htmlId}
      {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}
      className={`${getClassnameFromBackgroundColor.get(blade.backgroundColor || 'bg-white') ||
        'bg-white'}`}
    >
      <Container>
        <H3
          display={'block'}
          className="body-1"
          color="orange0"
          fontSize={2}
          fontWeight={6}
          marginBottom={1}
        >
          {blade?.sectionTitle}
        </H3>
        <H3 fontWeight={6} marginBottom={5}>
          {blade?.title}
        </H3>
        <Div>
          <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} className="guidelist">
            {blade?.accordionElements.map((item: ContentfulRichTextElement, index: number) => (
              <AccordionItem className="guidelist__item pv3" key={item.title}>
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`pointer guidelist__button flex justify-between ${
                      mouseDown ? 'button-outline' : ''
                    }`}
                  >
                    <>
                      <Span className="body-1 fw6">{item?.title}</Span>
                      <Span className="dropdown-button-plans flex items-center"></Span>
                    </>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Div paddingRight={5} marginTop={2}>
                    <RichTextRenderer
                      options={richTextOptions({
                        listIcon: (
                          <Icon
                            type="checkmark"
                            display="inline-flex"
                            marginRight={2}
                            flexShrink={0}
                            color="orange0"
                          />
                        ),
                        PARAGRAPH_styleprops: {
                          margin: 0,
                          lineHeight: 'copy',
                          color: 'black',
                        },
                      })}
                      richText={item?.column}
                    />
                  </Div>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
          {blade.longCopy && <MarkdownBlock source={blade.longCopy} renderers={markDownOptions} />}
        </Div>
      </Container>
    </Div>
  );
};
